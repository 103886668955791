import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, ThemeProvider, Grid, Box, Chip, TableHead, Table, TableBody, TableRow, TableCell, tableCellClasses, Link, Button } from "@mui/material";
import { useGetInvoiceDetailsQuery, useAddInvoivePaymentMutation } from "../../Shared/Store/Api/invoiceApi";
import { cardItem, plainAccordion, colorTheme } from "../../Common/Theme";
import Typography from '@mui/material/Typography';
import { ChevronLeft, KeyboardArrowDownOutlined, Payment } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { toast } from 'react-toastify';

const CustomerInvoiceDetails = ({ invoiceDetailId, setInvoiceDetailId, customerTitle }: any) => {

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colorTheme.palette.indigo400,
            color: '#fff',
            fontWeight: '600',
            padding: '8px',
            '&.text-right': {
                textAlign: 'right'
            }
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        '&:nth-of-type(odd)': {
            // backgroundColor: '#f4f4f4',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        'td': {
            padding: '8px',
            '&.text-right': {
                textAlign: 'right'
            }
        }
    }));

    const customerId = invoiceDetailId;
    const { data: invoiceDetails, refetch } = useGetInvoiceDetailsQuery(customerId);
    const [useAddInvoicePayment] = useAddInvoivePaymentMutation();
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({
        invoice_num: 0,
        unpaid_amount: 0,
        payment_mode: 'Cash',
        amount: '',
        transaction_code: '',
        desc: ''
    });

    const invoiceDates = invoiceDetails?.all_invoices || {};
    const invoiceProducts = invoiceDetails?.invoice_prod_details || {};

    const [expanded, setExpanded] = useState<string | false>('panel0');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const setInvoiceDetailsID = (e: any) => {
        setInvoiceDetailId(e);
    }

    const groupedInvoices: Record<string, Record<string, Record<string, any[]>>> = {};

    Object.keys(invoiceDates).sort((a, b) => {
        const dateA = new Date(invoiceDates[a].invoice_dt);
        const dateB = new Date(invoiceDates[b].invoice_dt);
        return dateB.getTime() - dateA.getTime();
    }).forEach((invoiceId) => {
        const invoiceDateParts = invoiceDates[invoiceId].invoice_dt.split('-');
        const day = parseInt(invoiceDateParts[0], 10);
        const month = parseInt(invoiceDateParts[1], 10) - 1;
        const year = parseInt(invoiceDateParts[2], 10);

        const invoiceDate = new Date(year, month, day);
        const yearKey = invoiceDate.getFullYear().toString();
        const monthKey = invoiceDate.toLocaleString('default', { month: 'long' });
        const dateKey = invoiceDate.getDate();

        if (!groupedInvoices[yearKey]) {
            groupedInvoices[yearKey] = {};
        }
        if (!groupedInvoices[yearKey][monthKey]) {
            groupedInvoices[yearKey][monthKey] = {};
        }
        if (!groupedInvoices[yearKey][monthKey][dateKey]) {
            groupedInvoices[yearKey][monthKey][dateKey] = [];
        }

        groupedInvoices[yearKey][monthKey][dateKey].push(invoiceDates[invoiceId]);
    });

    const sortedGroupedInvoices: any = Object.keys(groupedInvoices)
        .sort((a: any, b: any) => b - a) // Sort years in descending order
        .reduce((acc: any, year: any) => {
            acc[year] = Object.keys(groupedInvoices[year])
                .sort((a: any, b: any) => new Date(b + ' 1, 2000').getMonth() - new Date(a + ' 1, 2000').getMonth()) // Sort months in descending order
                .reduce((accMonth: any, month: any) => {
                    accMonth[month] = groupedInvoices[year][month];
                    return accMonth;
                }, {});
            return acc;
        }, {});
    function getDateSuffix(date: number): string {
        if (date >= 11 && date <= 13) {
            return 'th';
        }
        switch (date % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    const handlePaymentSubmit = async () => {
        try {
            const paidAmount = Number(paymentDetails.amount);
            const unpaidAmount = paymentDetails.unpaid_amount;
            const today = new Date();
            const formattedDate = today.toLocaleDateString('en-GB').split('/').join('-');

            await useAddInvoicePayment({
                invoice_num: paymentDetails.invoice_num,
                payments: [{
                    payment_mode: paymentDetails.payment_mode,
                    total_amt: unpaidAmount,
                    total_paid_amt: paidAmount,
                    transaction_code: paymentDetails.transaction_code,
                    status: "Paid",
                    desc: paymentDetails.desc,
                    payment_dt: formattedDate,
                    short_amt: 0,
                    created_by: 1
                }]
            }).unwrap();

            toast.success("Payment added successfully!");
            setOpenPaymentDialog(false);
            refetch();
        } catch (error) {
            toast.error("Failed to add payment!");
        }
    };

    return (
        <>
            <section id="main" className="flex flex-col overflow-auto flex-1 p-3 gap-3">
                <Grid container spacing={2} flexGrow={1}>
                    <Grid item xs={2}>
                        <Box sx={cardItem}>
                            <ThemeProvider theme={plainAccordion}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary> Purchase history </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container flexDirection="column" spacing={2}>
                                            {Object.keys(sortedGroupedInvoices).map((year, index) => (
                                                <Accordion defaultExpanded={index === 0} key={index} expanded={expanded === `${"panel" + index}`} onChange={handleChange(`${"panel" + index}`)}>
                                                    <AccordionSummary expandIcon={<KeyboardArrowDownOutlined />}>
                                                        <Typography>{year}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container flexDirection="column" spacing={2}>
                                                            {Object.keys(sortedGroupedInvoices[year]).map((month) => (
                                                                <Accordion key={month}>
                                                                    <AccordionSummary expandIcon={<KeyboardArrowDownOutlined />}>
                                                                        <Typography>{month}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Grid container flexDirection="column" spacing={2}>
                                                                            {Object.keys(sortedGroupedInvoices[year][month]).map((date) => (
                                                                                <Accordion key={date}>
                                                                                    <AccordionSummary expandIcon={<KeyboardArrowDownOutlined />}>
                                                                                        <Typography>{date + getDateSuffix(parseInt(date))}</Typography>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails>
                                                                                        <Grid container flexDirection="column" spacing={2}>
                                                                                            {sortedGroupedInvoices[year][month][date].map((invoice: any) => (
                                                                                                <Link
                                                                                                    key={invoice.dt_sequence_no}
                                                                                                    href="#"
                                                                                                    className={invoice.current_invoice === true ? 'active' : ''}
                                                                                                    onClick={() => {
                                                                                                        setInvoiceDetailsID(invoice.dt_sequence_no);
                                                                                                    }}
                                                                                                >
                                                                                                    {invoice.dt_sequence_no}
                                                                                                </Link>
                                                                                            ))}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            ))}
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            ))}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={cardItem}>
                            <Grid container spacing={1} flexGrow={0}>
                                <Grid item sx={{ marginBottom: 0 }} >
                                    <Typography variant="body1" sx={{ fontWeight: "600", color: colorTheme.palette.indigo500 }}>
                                        <span className='text-primaryDark'>Invoice#:</span>
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "600", color: colorTheme.palette.indigo500 }}>
                                        {invoiceDetails?.dt_sequence_no}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: "600", color: colorTheme.palette.indigo500 }}>
                                        <span className='text-primaryDark'>Customer:</span>
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "600", color: colorTheme.palette.indigo500 }}>{invoiceDetails?.customer_name}</Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>{invoiceDetails?.customer_contact}</Typography>

                                    {/* {(invoiceDetails?.payment_status === "Unpaid" || invoiceDetails?.payment_status === "Payment Pending" || invoiceDetails?.payment_status === "Hold") && ( */}
                                    <Grid item sx={{ marginTop: '10px' }}>
                                        <Button
                                            sx={{
                                                padding: '6px 16px',
                                                color: '#fff',
                                                backgroundColor: colorTheme.palette.indigo500,
                                                '&:hover': { backgroundColor: colorTheme.palette.indigo500 },
                                                fontWeight: '600'
                                            }}
                                            variant="contained"
                                            startIcon={<Payment />}
                                            onClick={() => {
                                                setPaymentDetails({
                                                    invoice_num: invoiceDetails.dt_sequence_no,
                                                    unpaid_amount: invoiceDetails.unpaid_amt || 0,
                                                    payment_mode: 'Cash',
                                                    amount: '',
                                                    transaction_code: '',
                                                    desc: ''
                                                });
                                                setOpenPaymentDialog(true);
                                            }}
                                        >
                                            Make Payment
                                        </Button>
                                    </Grid>
                                    {/* )} */}

                                </Grid>
                                <Grid item sx={{ marginLeft: 'auto' }}>
                                    <Typography variant="body1" sx={{ fontWeight: "600", color: colorTheme.palette.indigo500 }}>
                                        <span className='text-primaryDark'>Invoice details:</span>
                                    </Typography>
                                    <table className="customTable invoiceDetail my-3">
                                        <thead>
                                            <tr>
                                                <th className='text-left'>Date</th>
                                                <th className='text-left'>Time</th>
                                                <th className='text-left'>Returns</th>
                                                <th className='text-left'>Payment mode</th>
                                                <th className='text-left'>Transaction code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{invoiceDetails?.invoice_dt}</td>
                                                <td>{invoiceDetails?.invoice_time}</td>
                                                <td>-</td>
                                                <td>{invoiceDetails?.customer_payments[0]?.payment_mode_name}</td>
                                                <td>{invoiceDetails?.customer_payments[0]?.transaction_code}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="customTable invoiceDetail my-3">
                                        <thead>
                                            <tr>
                                                <th className="text-left">No. of Items</th>
                                                <th className="text-center">Payment status</th>
                                                <th className="text-left">Returned item</th>
                                                <th className="text-right">Subtotal</th>
                                                <th className="text-right">Discount</th>
                                                <th className="text-right">Total tax</th>
                                                <th className="text-right">Net total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{invoiceDetails?.total_items}</td>
                                                <td className='text-center'>
                                                    <Chip label={invoiceDetails?.payment_status} size="small" variant="outlined"
                                                        sx={{
                                                            minWidth: '60px',
                                                            fontSize: '10px',
                                                            textTransform: 'uppercase',
                                                            fontWeight: '600',
                                                            letterSpacing: '0.5px',
                                                            lineHeight: '14px',
                                                            borderWidth: '1px',
                                                            borderStyle: '1px solid',
                                                            backgroundColor: invoiceDetails?.payment_status == "Paid" ? colorTheme.palette.green50 : invoiceDetails?.payment_status == "Unpaid" ? colorTheme.palette.red50 : invoiceDetails?.payment_status == "Pending" ? colorTheme.palette.grey50 : colorTheme.palette.amber50,
                                                            borderColor: invoiceDetails?.payment_status == "Paid" ? colorTheme.palette.green500 : invoiceDetails?.payment_status == "Unpaid" ? colorTheme.palette.red500 : invoiceDetails?.payment_status == "Pending" ? colorTheme.palette.grey500 : colorTheme.palette.amber500,
                                                            color: invoiceDetails?.payment_status == "Paid" ? colorTheme.palette.green500 : invoiceDetails?.payment_status == "Unpaid" ? colorTheme.palette.red500 : invoiceDetails?.payment_status == "Pending" ? colorTheme.palette.grey500 : colorTheme.palette.amber500,
                                                        }}
                                                    />
                                                </td>
                                                <td>-</td>
                                                <td className="text-right">₹&nbsp;{invoiceDetails?.sub_total}/-</td>
                                                <td className="text-right">₹&nbsp;{invoiceDetails?.disc_amt}</td>
                                                <td className="text-right">₹&nbsp;{parseFloat(invoiceDetails?.total_tax_amt || 0).toFixed(2)}</td>
                                                <td className="text-right">₹&nbsp;{invoiceDetails?.net_total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} flexGrow={1}>
                                <Grid item xs={12} sx={{ marginBottom: 'auto' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Item list</StyledTableCell>
                                                <StyledTableCell>Item code</StyledTableCell>
                                                <StyledTableCell>Size</StyledTableCell>
                                                <StyledTableCell>Color</StyledTableCell>
                                                <StyledTableCell>Qty</StyledTableCell>
                                                <StyledTableCell className='text-right'>Price</StyledTableCell>
                                                <StyledTableCell className='text-right'>Discount</StyledTableCell>
                                                <StyledTableCell className='text-right'>Subtotal</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoiceProducts.length ? (
                                                invoiceProducts.map((item: any, index: number) => (
                                                    <StyledTableRow key={index}>
                                                        <TableCell>{item.product_title}</TableCell>
                                                        <TableCell>{item.prod_sku}</TableCell>
                                                        <TableCell>{item.colour}</TableCell>
                                                        <TableCell>{item.size}</TableCell>
                                                        <TableCell>{item.quantity}</TableCell>
                                                        <TableCell className='text-right'>₹&nbsp;{item.unit_price}</TableCell>
                                                        <TableCell className='text-right'>{item.disc_amt ? item.disc_amt : 0}</TableCell>
                                                        <TableCell className='text-right'>₹&nbsp;{item.net_total}</TableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) :
                                                (
                                                    <StyledTableRow>
                                                        <TableCell colSpan={7}>No data found</TableCell>
                                                    </StyledTableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: 'auto' }}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                sx={{ fontWeight: "600", color: colorTheme.palette.indigo500, alignItems: 'center', padding: 0, display: 'flex', lineHeight: '25px' }}
                                                onClick={() => {
                                                    setInvoiceDetailId('');
                                                }}
                                            >
                                                <ChevronLeft />
                                                {customerTitle ? 'Back to Customers' : 'Back to Invoices'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </section>

            {/* Payment Dialog */}
            <Dialog
                open={openPaymentDialog}
                onClose={() => setOpenPaymentDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Make Payment</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                label="Unpaid Amount"
                                value={paymentDetails.unpaid_amount.toFixed(2)}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Payment Mode</InputLabel>
                                <Select
                                    value={paymentDetails.payment_mode}
                                    label="Payment Mode"
                                    onChange={(e) => setPaymentDetails({
                                        ...paymentDetails,
                                        payment_mode: e.target.value,
                                        transaction_code: ''
                                    })}
                                >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="Credit Card">Card</MenuItem>
                                    <MenuItem value="UPI">UPI</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {paymentDetails.payment_mode === 'Credit Card' && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Transaction Code"
                                    value={paymentDetails.transaction_code}
                                    onChange={(e) => setPaymentDetails({
                                        ...paymentDetails,
                                        transaction_code: e.target.value
                                    })}
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                label="Amount"
                                type="number"
                                value={paymentDetails.amount}
                                onChange={(e) => setPaymentDetails({
                                    ...paymentDetails,
                                    amount: e.target.value
                                })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                value={paymentDetails.desc}
                                onChange={(e) => setPaymentDetails({
                                    ...paymentDetails,
                                    desc: e.target.value
                                })}
                                fullWidth
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPaymentDialog(false)}>Cancel</Button>
                    <Button
                        onClick={handlePaymentSubmit}
                        variant="contained"
                        disabled={!paymentDetails.amount || Number(paymentDetails.amount) <= 0}
                    >
                        Submit Payment
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CustomerInvoiceDetails;
