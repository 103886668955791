import { ThemeProvider } from '@mui/material/styles';
import { Grid, Button } from "@mui/material";
import storySet from '../../Assets/images/dashboard-story.svg';
import { btnTheme, colorTheme } from "../../Common/Theme";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useLazyGetMasterTaxQuery } from "../../Shared/Store/Api/productApi";
import { IMasterTax, TMasterTaxList } from '../../Shared/Types/types';

function Dashboard() {
    // const [taxMstData, setTaxMstData] = useState<IMasterTax[] | null>(null);
    const [trigger] = useLazyGetMasterTaxQuery();
    

    useEffect(() => {
        const fetchData = async () => {
          try {
            
            const localData = localStorage.getItem('mstTaxesData');
            if (localData) {
                console.log('Loading All Taxes data from localStorage...');
                // setTaxMstData(JSON.parse(localData) as IMasterTax[]);
            } else {
                console.log('Fetching All Taxes data from API...');
                const data = await trigger(1).unwrap();
                
                if (data) {
                    
                    const allTaxes = data['allTaxes']
                    const mTaxList : TMasterTaxList = []

                    allTaxes.forEach((oTax: IMasterTax) => {
                        mTaxList.push(oTax)
                    });
                    // console.log("mTaxList : ", mTaxList)
                    
                    localStorage.setItem('mstTaxesData', JSON.stringify(mTaxList));
                }
                
            }
          } catch (error) {
            console.error('Error fetching All Taxes data :', error);
          }
        };
    
        fetchData();
      }, [trigger]);

    //   useEffect(() => {
    //     if (data) {
    //         console.log("in data : ", data)
    //         setTaxMstData(data);
    //         console.log(taxMstData)
    //         localStorage.setItem('mstTaxesData', JSON.stringify(data));
    //     }
    //   }, [data]);

    return (
        <>
            <Grid className="flex-1" container>
                <Grid item xs={6} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <img src={storySet} className="object-contain h-full w-full" alt="Dashboard story set" />
                </Grid>
                <Grid item xs={6} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Grid container sx={{ justifyContent: "center", alignItems: "center" }} spacing={1} >
                        <Grid item>

                            <ThemeProvider theme={btnTheme}>
                                <Button variant='contained'><Link to='/billing'>Add new invoice</Link></Button>
                            </ThemeProvider>
                        </Grid>
                        <Grid item>
                            <ThemeProvider theme={btnTheme}>
                                <Button variant='contained' sx={{ backgroundColor: colorTheme.palette.logoPink, '&:hover': { backgroundColor: colorTheme.palette.logoPinkHover } }}>
                                    <Link to='/customers'>Add new customer</Link>
                                </Button>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Dashboard;
