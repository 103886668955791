import sdfLogo from '../../Assets/images/sdf-logo.png';
import shree from '../../Assets/images/shree.png';
import React from 'react';
import './PDF.css';

interface InvoicePDFProps {
    customerNameFormBilling: string;
    tempBillNo: any;
    billingSaleSummary: any;
    billingSelectedProduct: any;
    customerMobile: any;
    billNo: any;
}

const InvoicePDF = React.forwardRef<HTMLDivElement, InvoicePDFProps>(
    ({ customerNameFormBilling, billingSaleSummary, tempBillNo, billingSelectedProduct, customerMobile, billNo }, ref) => {
        const customerName = customerNameFormBilling || 'Walk-in Customer';
        const customerPhone = customerMobile || "";
        const billNumber = billNo || tempBillNo;

        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const todayDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;

        const numberToWords = (num: number) => {
            const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
            const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
            const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

            let words = '';

            if (num === 0) {
                return 'zero';
            }
            if (num >= 1000) {
                words += numberToWords(Math.floor(num / 1000)) + ' thousand ';
                num %= 1000;
            }
            if (num >= 100) {
                words += ones[Math.floor(num / 100)] + ' hundred ';
                num %= 100;
            }
            if (num >= 20) {
                words += tens[Math.floor(num / 10)] + ' ';
                num %= 10;
            }
            if (num > 10 && num < 20) {
                words += teens[num - 11] + ' ';
                num = 0;
            }
            if (num > 0) {
                words += ones[num] + ' ';
            }
            return words.trim().toUpperCase();
        };

        const itemsPerPage = 10;
        const totalPages = Math.ceil(billingSelectedProduct.length / itemsPerPage);

        const productPages = Array.from({ length: totalPages }, (_, pageIndex) => {
            const startIndex = pageIndex * itemsPerPage;
            return billingSelectedProduct.slice(startIndex, startIndex + itemsPerPage);
        });

        return (
            <>
                <div className="table-outer" ref={ref}>
                    {productPages.map((pageProducts, pageIndex) => (
                        <div key={pageIndex} className={pageIndex === totalPages - 1 ? '' : 'page-break'}>
                            <table className='printPDFTable'>
                                <thead>
                                    <tr>
                                        <td className="logo" style={{ verticalAlign: 'middle' }}>
                                            <img src={sdfLogo} alt="SDF" width="141" height="45" />
                                            <p style={{ color: '#D13F80', fontFamily: 'Gabriola', fontSize: '15px', fontWeight: '400', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px' }}>
                                                a venture of Gangaram Enterprises
                                            </p>
                                            {/* <p style={{ color: '#53266A', fontFamily: 'Gabriola', fontSize: '15px', fontWeight: '400', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px' }}>
                                                exclusive ladies gowns, kurtis, leggings, palazzos and  more
                                            </p> */}
                                            <p style={{
                                                color: '#000', fontSize: '12px', fontWeight: '500', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px'
                                            }}>Contact no. : +91 7600070053</p>
                                            <p style={{ verticalAlign: 'bottom', color: '#F00', fontSize: '12px', fontWeight: '700', letterSpacing: '-0.081px', padding: '5px 5px 0' }}>
                                                GSTIN: 24AGBPA8175F1ZQ
                                            </p>
                                        </td>
                                        <td className="logo" style={{ textAlign: 'center' }}>
                                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={shree} alt="SDF" />
                                        </td>
                                        <td>
                                            <p style={{ fontSize: 13, color: '#000', textTransform: 'uppercase', fontWeight: 500, margin: '10px 0 0' }}>Retail Invoice</p>

                                            <p style={{
                                                color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '3px 0 0'
                                            }}>Invoice no.: <span style={{ fontWeight: '400' }}>{billNumber}</span></p>

                                            <p style={{
                                                color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '3px 0 0'
                                            }}>Date : <span style={{ fontWeight: '400' }}>{todayDate}</span></p>
                                            <p style={{
                                                color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '3px 0 0'
                                            }}>Name : <span style={{ fontWeight: '400' }}>{customerName}</span></p>
                                            <p style={{
                                                color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '3px 0 0'
                                            }}>Mobile : <span style={{ fontWeight: '400' }}>{customerPhone}</span></p>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <table className='productDetals' style={{ minHeight: '255px' }}>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>Sr. No.</th>
                                                        <th className='text-left'>Description</th>
                                                        <th className='text-center'>Size</th>
                                                        <th className='text-center'>HSN</th>
                                                        <th className='text-center'>Qty.</th>
                                                        <th className='text-center'>MRP</th>
                                                        <th className='text-center'>Price</th>
                                                        <th className='text-center'>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ verticalAlign: 'top' }}>
                                                    {pageProducts.map((product: any, index: number) => (
                                                        <tr key={index}>
                                                            <td className='text-center'>{pageIndex * itemsPerPage + index + 1}</td>
                                                            <td>{product.prod_sku} ({product.prod_title})</td>
                                                            <td className=''>{product.size}</td>
                                                            <td className='text-center'>{product.hsn_code}</td>
                                                            <td className='text-center'>{product.quantity}</td>
                                                            <td className='text-center'>
                                                                {product.mrp > 0 ? product.mrp : product.unit_price}
                                                            </td>
                                                            <td className='text-center'>{product.unit_price}</td>
                                                            <td className='text-center'>{parseFloat(product.net_total || 0).toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                    {Array.from({ length: Math.max(0, itemsPerPage - pageProducts.length) }).map((_, index) => (
                                                        <tr key={`empty-${index}`}>
                                                            <td colSpan={8}>&nbsp;</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>

                                {pageIndex < totalPages - 1 && (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={3}>
                                                <table style={{ tableLayout: 'auto', width: '100%' }}>
                                                    <tr><th colSpan={3} style={{ borderBottom: '1px solid #eee' }}>&nbsp;</th></tr>
                                                    <tr className='amount-row'>
                                                        <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal', width: '60%' }}></th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', width: '20%' }}>Subtotal</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', width: '20%' }}>
                                                            ₹ {parseFloat(pageProducts.reduce((sum: number, product: { net_total: any; }) =>
                                                                sum + parseFloat(product.net_total || 0), 0
                                                            ).toFixed(2))}
                                                        </th>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tfoot>
                                )}

                                {pageIndex === totalPages - 1 && (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={3}>
                                                <table style={{ tableLayout: 'auto' }}>
                                                    <tr><th colSpan={3} style={{ borderBottom: '1px solid #eee' }}>&nbsp;</th></tr>
                                                    <tr className='amount-row'>
                                                        <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}>Amount in words:&nbsp; Rs.&nbsp; {numberToWords(Math.round(parseFloat(billingSaleSummary.netTotal)))} &nbsp;ONLY</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>No. of items</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                            {billingSaleSummary.total_items}
                                                        </th>
                                                    </tr>
                                                    <tr className='amount-row'>
                                                        <th style={{ paddingBottom: '2px', backgroundColor: 'transparent', textAlign: 'left', lineHeight: 'normal', fontSize: '12px', color: '#4caf50' }}>You saved: ₹ {Math.round(parseFloat(billingSaleSummary.MRPDiscValue || 0)).toFixed(2)}</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>MRP total</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                            ₹ {parseFloat(billingSaleSummary.netTotal + billingSaleSummary.MRPDiscValue || 0).toFixed(2)}
                                                        </th>
                                                    </tr>
                                                    <tr className='amount-row'>
                                                        <th style={{ paddingBottom: '2px', backgroundColor: 'transparent', textAlign: 'left', lineHeight: 'normal', fontSize: '12px', color: '#4caf50' }}></th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>Subtotal</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                            ₹ {parseFloat(billingSaleSummary.subTotal || 0).toFixed(2)}
                                                        </th>
                                                    </tr>

                                                    {parseFloat(billingSaleSummary.discountValue || 0) === 0
                                                        ? <tr className='amount-row'>
                                                            <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>&nbsp;</th>
                                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                                &nbsp;
                                                            </th>
                                                        </tr>
                                                        :
                                                        <tr className='amount-row'>
                                                            <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>Discount</th>
                                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                                ₹ {parseFloat(billingSaleSummary.discountValue || 0).toFixed(2)}
                                                            </th>
                                                        </tr>
                                                    }
                                                    <tr className='copyright'>
                                                        <th style={{ paddingBottom: '2px', backgroundColor: 'transparent', textAlign: 'left', lineHeight: 'normal', fontSize: '12px', color: '#000' }}>Thank you for your purchase</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px' }}>Net amount</th>
                                                        <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px' }}>
                                                            ₹ {Math.round(parseFloat(billingSaleSummary.netTotal || 0)).toFixed(2)}
                                                        </th>
                                                    </tr>
                                                    <tr className='copyright'>
                                                        <td>
                                                            <p>Terms and Conditions</p>
                                                            <ul>
                                                                <li>Subject to Vadodara jurisdiction.</li>
                                                                <li>Goods once sold will not be returned or exchanged.</li>
                                                                <li>Goods sold as is, not guarantee of work, color & material.</li>
                                                                <li>E. & O.E</li>
                                                            </ul>
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>SB- 28, Siddharth complex, Nr Express Hotel,<br />RC Dutt road, Alkapuri, Vadodara- 390007, Guj.
                                                            <p style={{ textWrap: 'nowrap' }}>
                                                                <strong>info@shudhdesifashion.com, www.shudhdesifashion.com</strong>
                                                            </p>
                                                        </td>
                                                        <td style={{ textAlign: 'center', verticalAlign: 'bottom', fontSize: '10px' }}>
                                                            <p style={{ borderTop: '1px solid #eee', lineHeight: '5px' }}>&nbsp;</p>
                                                            <p style={{ marginBottom: '5px' }}>Signature</p>
                                                            <p><strong>For Gangaram Enterprises</strong></p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tfoot>
                                )}
                            </table>
                        </div>
                    ))}
                    <footer></footer>
                    <div className='tableFooter'>
                        <table>
                        </table>
                    </div>
                </div>
            </>
        )
    });

export default InvoicePDF;