import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Box, Grid, Pagination, FormControl, InputLabel, Select, MenuItem, OutlinedInput, InputAdornment, IconButton, SelectChangeEvent, Table, TableHead, TableRow, TableBody, TableCell, tableCellClasses, Button, Typography, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { useGetCustomerDetailsQuery } from "../../Shared/Store/Api/customerApi";
import { cardItem, colorTheme, btnTheme } from '../../Common/Theme';
import { Search, VisibilityOutlined, NoteAdd, SpeakerNotes } from '@mui/icons-material';
import DynamicSearchBox from '../../Common/DynamicSearchBox';
import { ThemeProvider } from '@mui/material/styles';
import { useCreateCustomerMutation } from '../../Shared/Store/Api/customerApi';
import { Form } from '../../Common/Form';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { customerAdditionalValidationSchema } from '../../Shared/Validation/validations';
import CustomerInvoiceDetails from '../../Components/CustomerInvoices/CustomerInvoiceDetails';
import { useAddCustomerNoteMutation } from '../../Shared/Store/Api/customerApi';

const Customers = () => {
   const [createCustomer] = useCreateCustomerMutation();
   const [useAddCustomerNote] = useAddCustomerNoteMutation();
   const [openNoteDialog, setOpenNoteDialog] = useState(false);
   const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
   const [customerNote, setCustomerNote] = useState('');

   const StyledTableCell = styled(TableCell)(() => ({
      [`&.${tableCellClasses.head}`]: {
         backgroundColor: colorTheme.palette.logoPink,
         color: '#fff',
         fontWeight: '600',
         padding: '8px',
         whiteSpace: 'nowrap'
      },
   }));

   const StyledTableRow = styled(TableRow)(() => ({
      '&:nth-of-type(odd)': {
         // backgroundColor: '#f4f4f4',
      },
      // hide last border
      '&:last-child td, &:last-child th': {
         border: 0,
      },
      'td': {
         padding: '8px'
      }
   }));

   const customerForm = useForm({ mode: 'all', resolver: yupResolver(customerAdditionalValidationSchema) });

   // create new customer handler
   const createCustomerHandler = async (customer: any) => {
      try {
         await createCustomer(customer).unwrap().then((res: any) => {
            if (res.existing_customer === false) {
               toast.success("Customer created successfully!");
               customerForm.reset({ contact_name_1: "", contact_no_1: "" });
               refetch();
            } else {
               toast.error("Customer already exists.");
            }
         });
      } catch (error) {
         toast.error("Something went wrong!");
         customerForm.reset();
      }
   };

   const [limit, setLimit] = useState(10);
   const [offset, setOffset] = useState(0);
   const [search, setSearch] = useState("");
   const [contentPerPage, setContentPerPage] = useState('10');

   const { data: customers, refetch } = useGetCustomerDetailsQuery({ search: search, limit, offset });
   const customerDetails = customers?.results || [];
   const count = customers?.count || 0;


   useEffect(() => {
      refetch();
   }, [limit, offset, refetch]);

   const handlePageChange = (event: any, value: any) => {
      setOffset((value - 1) * limit);
      refetch();
   };
   const handleSearch = (e: any) => {
      setOffset(0);
      setSearch(e.target.value);
      refetch();
   };
   const handleContentPerPageChange = (e: SelectChangeEvent) => {
      setContentPerPage(e.target.value as string);
      setLimit(parseInt(e.target.value, 10));
      setOffset(0);
      refetch();
   };

   const [invoiceDetailId, setInvoiceDetailId] = useState();

   const handleAddNote = async () => {
      try {
         await useAddCustomerNote({
            customer_code: selectedCustomer,
            notes: customerNote
         }).unwrap();
         toast.success("Note added successfully!");
         setOpenNoteDialog(false);
         setCustomerNote('');
         refetch();
      } catch (error) {
         toast.error("Failed to add note!");
      }
   };

   return (
      <>
         {invoiceDetailId ? (
            <CustomerInvoiceDetails
               invoiceDetailId={invoiceDetailId}
               customerTitle={true}
               setInvoiceDetailId={(e: any) => setInvoiceDetailId(e)}
            />
         ) : (
            <section id="main" className="flex flex-col overflow-auto flex-1 p-3 gap-3">
               <Grid container spacing={2} flexGrow={1}>
                  <Grid item xs={9}>
                     <Box sx={cardItem}>
                        <Grid container alignItems="center" spacing={2}>
                           <Grid item xs={3} >
                              <DynamicSearchBox>
                                 <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                    <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                    <OutlinedInput
                                       value={search}
                                       onChange={handleSearch}
                                       type='text'
                                       endAdornment={
                                          <InputAdornment position="end">
                                             <IconButton>
                                                <Search />
                                             </IconButton>
                                          </InputAdornment>
                                       }
                                       label="Search"
                                    />
                                 </FormControl >
                              </DynamicSearchBox>
                           </Grid>
                           <Grid item xs={3}>
                              <div className='flex items-center gap-2'>
                                 <DynamicSearchBox>
                                    <FormControl fullWidth variant="outlined" size='small'>
                                       <InputLabel id="content-per-page-label">Content per page</InputLabel>
                                       <Select
                                          labelId="content-per-page-label"
                                          id="content-per-page"
                                          value={contentPerPage}
                                          label="Content per page"
                                          onChange={handleContentPerPageChange}
                                       >
                                          <MenuItem value={10} selected>10</MenuItem>
                                          <MenuItem value={20}>20</MenuItem>
                                          <MenuItem value={30}>30</MenuItem>
                                          <MenuItem value={40}>40</MenuItem>
                                          <MenuItem value={50}>50</MenuItem>
                                       </Select>
                                    </FormControl>
                                 </DynamicSearchBox>
                              </div>
                           </Grid>
                        </Grid>
                        <Table stickyHeader >
                           <TableHead>
                              <TableRow>
                                 <StyledTableCell align='left' sx={{ minWidth: '50px' }}>Customer code</StyledTableCell>
                                 <StyledTableCell align='left'>Customer/Brand name</StyledTableCell>
                                 <StyledTableCell align='left'>Contact number</StyledTableCell>
                                 <StyledTableCell align='left'>Credits</StyledTableCell>
                                 <StyledTableCell align='left'>Customer since</StyledTableCell>
                                 <StyledTableCell align='left'>Last purchased on</StyledTableCell>
                                 <StyledTableCell align='center'>Total invoices</StyledTableCell>
                                 <StyledTableCell align="center">&nbsp;</StyledTableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>

                              {customerDetails?.length ? (
                                 customerDetails?.map((customer: any, index: number) => (
                                    <StyledTableRow key={index}>
                                       <TableCell sx={{ minWidth: '50px', textOverflow: 'clip' }}>{customer.customer_code}</TableCell>
                                       <TableCell>{customer.contact_name_1 ? customer.contact_name_1 : customer.customer_brand_name}</TableCell>
                                       <TableCell>{customer.contact_no_1}</TableCell>
                                       <TableCell>{customer.credits}</TableCell>
                                       <TableCell>{customer.dt_joined}</TableCell>
                                       <TableCell>{customer.last_purchase_date ? customer.last_purchase_date : 'Not available'}</TableCell>
                                       <TableCell align='center'>{customer.total_invoices}</TableCell>

                                       <TableCell align="center">
                                          <Grid container spacing={1} justifyContent="left">
                                             {customer.first_purchase_id ? (
                                                <Grid item>
                                                   <Tooltip placement="left" title="View invoice">
                                                      <Button
                                                         sx={{
                                                            padding: 0,
                                                            border: 0,
                                                            minWidth: '24px',
                                                            color: colorTheme.palette.topLink,
                                                            '&:hover': { border: 0, color: colorTheme.palette.green600 },
                                                            fontWeight: '600'
                                                         }}
                                                         variant="text"
                                                         onClick={() => {
                                                            setInvoiceDetailId(customer.first_purchase_id);
                                                         }}
                                                      >
                                                         <VisibilityOutlined />
                                                      </Button>
                                                   </Tooltip>
                                                </Grid>
                                             ) : null}
                                             <Grid item>
                                                <Tooltip placement="right" title={customer.notes || "No notes available"}>
                                                   <Button
                                                      sx={{
                                                         padding: 0,
                                                         border: 0,
                                                         minWidth: '24px',
                                                         color: colorTheme.palette.topLink,
                                                         '&:hover': { border: 0, color: colorTheme.palette.green600 },
                                                         fontWeight: '600'
                                                      }}
                                                      variant="text"
                                                   >
                                                      <SpeakerNotes />
                                                   </Button>
                                                </Tooltip>
                                             </Grid>
                                             <Grid item>
                                                <Tooltip placement="right" title="Add Note">
                                                   <Button
                                                      sx={{
                                                         padding: 0,
                                                         border: 0,
                                                         minWidth: '24px',
                                                         color: colorTheme.palette.topLink,
                                                         '&:hover': { border: 0, color: colorTheme.palette.green600 },
                                                         fontWeight: '600'
                                                      }}
                                                      variant="text"
                                                      onClick={() => {
                                                         setSelectedCustomer(customer.customer_code);
                                                         setOpenNoteDialog(true);
                                                      }}
                                                   >
                                                      <NoteAdd />
                                                   </Button>
                                                </Tooltip>
                                             </Grid>
                                          </Grid>
                                       </TableCell>

                                    </StyledTableRow>
                                 ))
                              ) : (
                                 <StyledTableRow>
                                    <TableCell>No data found</TableCell>
                                 </StyledTableRow>
                              )
                              }
                           </TableBody>
                        </Table>
                        <Grid container justifyContent="end" sx={{ marginTop: 'auto' }}>
                           <Pagination
                              count={Math.ceil(count / limit)}
                              page={offset / limit + 1}
                              onChange={handlePageChange}
                           />
                        </Grid>
                     </Box >
                  </Grid>
                  <Grid item xs={3}>
                     <Box sx={cardItem}>
                        <Typography id="modal-modal-title" variant="subtitle1" sx={{ color: colorTheme.palette.logoPink, fontWeight: 'bold' }}>
                           New Customer
                        </Typography>
                        <DynamicSearchBox>
                           <Form reactFormContext={customerForm} onSubmit={createCustomerHandler}>
                              <Grid container spacing={2}>
                                 <Grid item xs={12}>
                                    <Form.Input name="contact_name_1" label="Name" placeholder="John Doe" size='small' variant="standard" />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Form.Input name="contact_no_1" label="Mobile" placeholder="1234567890" size='small' variant="standard" />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Form.Input name="contact_email_1" label="Email" placeholder="email" size='small' variant="standard" />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Form.Input name="notes" label="Notes" placeholder="notes" size='small' variant="standard" multiline
                                       rows={4} />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Grid container alignItems='center' justifyContent='center' spacing={2}>
                                       <Grid item>
                                          <ThemeProvider theme={btnTheme}>
                                             <Button type="submit" sx={{ whiteSpace: 'nowrap', backgroundColor: colorTheme.palette.logoPink, '&:hover': { backgroundColor: colorTheme.palette.logoPinkHover } }} variant="contained" size='medium'>
                                                Add new customer
                                             </Button>
                                          </ThemeProvider>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Form>
                        </DynamicSearchBox>
                     </Box>
                  </Grid>
               </Grid>
            </section>
         )
         }

         {/* Add Note Dialog */}
         <Dialog
            open={openNoteDialog}
            onClose={() => {
               setOpenNoteDialog(false);
               setCustomerNote('');
            }}
            maxWidth="sm"
            fullWidth
         >
            <DialogTitle>Add Customer Note</DialogTitle>
            <DialogContent>
               <TextField
                  autoFocus
                  margin="dense"
                  label="Note"
                  fullWidth
                  multiline
                  rows={4}
                  value={customerNote}
                  onChange={(e) => setCustomerNote(e.target.value)}
                  variant="outlined"
               />
            </DialogContent>
            <DialogActions>
               <Button
                  onClick={() => {
                     setOpenNoteDialog(false);
                     setCustomerNote('');
                  }}
                  color="primary"
               >
                  Cancel
               </Button>
               <Button
                  onClick={handleAddNote}
                  color="primary"
                  variant="contained"
                  disabled={!customerNote.trim()}
               >
                  Add Note
               </Button>
            </DialogActions>
         </Dialog>
      </>
   )
}
export default Customers;