import { styled } from '@mui/material/styles';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    tableCellClasses,
    Chip,
    Grid,
    Link,
    Pagination,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Select,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from "@mui/material";
import { useGetInvoiceNewQuery, useAddInvoiveNoteMutation, useAddInvoivePaymentMutation } from "../../Shared/Store/Api/invoiceApi";
import { colorTheme, cardItem, plainAccordion, btnTheme } from "../../Common/Theme";
import { useState } from 'react';
import { Search, VisibilityOutlined, NoteAdd, SpeakerNotes, Payment } from "@mui/icons-material";
import { ThemeProvider } from '@mui/material/styles';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import DynamicSearchBox from '../../Common/DynamicSearchBox';
import { useNavigate } from 'react-router-dom';
import PrintButton from './PrintButton';
import { toast } from 'react-toastify';

const Status = {
    "": "",
    "paid": "Paid",
    "unpaid": "Unpaid",
    "pending": "Pending",
    "hold": "Hold",
};


const CustomerInvoicesTable = ({ setInvoiceDetailId }: any) => {
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colorTheme.palette.indigo400,
            color: '#fff',
            fontWeight: '600',
            padding: '8px'
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        '&:nth-of-type(odd)': {
            // backgroundColor: '#f4f4f4',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        'td': {
            padding: '8px'
        }
    }));


    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const formatDateToYYYYMMDD = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [selectedStartDate, setSelectedStartDate] = useState(formatDateToYYYYMMDD(new Date(currentYear, currentMonth, 1)));
    const [selectedEndDate, setSelectedEndDate] = useState(formatDateToYYYYMMDD(new Date()));
    const [activeMonth, setActiveMonth] = useState({ year: currentYear, monthIndex: 0 });
    const [expanded, setExpanded] = useState<string | false>('panel0');
    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [contentPerPage, setContentPerPage] = useState('10');
    const [statusFilters, setStatusFilters] = useState<string>("");
    const [useAddInvoiceNote] = useAddInvoiveNoteMutation();
    const [openNoteDialog, setOpenNoteDialog] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<any | null>(null);
    const [invoiceNote, setInvoiceNote] = useState('');
    const [useAddInvoicePayment] = useAddInvoivePaymentMutation();
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({
        invoice_num: 0,
        unpaid_amount: 0,
        payment_mode: 'Cash',
        amount: '',
        transaction_code: '',
        desc: ''
    });

    const [page, setPage] = useState(1);
    const itemsPerPage = parseInt(contentPerPage);

    const { data: invoices = [], refetch } = useGetInvoiceNewQuery({
        search_txt: search,
        inv_status: statusFilters,
        start_date: selectedStartDate,
        end_date: selectedEndDate
    });

    const totalPages = Math.ceil(invoices.length / itemsPerPage);
    const startIndex = (page - 1) * itemsPerPage;
    const paginatedInvoices = invoices.slice(startIndex, startIndex + itemsPerPage);

    const handleSearch = (e: any) => {
        setSearch(e.target.value);
        refetch();
    };

    const handleStatusChange = (newStatusFilter: string) => {
        setStatusFilters(newStatusFilter);
        refetch();
    }

    const getMonthNames = (year: number, currentYear: number, currentMonth: number) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (year === currentYear) {
            const months = monthNames.slice(0, currentMonth + 1).reverse();
            if (months.length > 0) months[0] = "This month";
            if (months.length > 1) months[1] = "Last month";
            return months;
        } else {
            return monthNames.reverse();
        }
    };

    const handleMonthClick = (year: number, monthIndex: number) => {
        const actualMonthIndex = year === currentYear ?
            currentMonth - monthIndex :
            11 - monthIndex;

        const startDate = new Date(year, actualMonthIndex, 1);
        const endDate = new Date(year, actualMonthIndex + 1, 0);

        const formattedStartDate = formatDateToYYYYMMDD(startDate);
        const formattedEndDate = formatDateToYYYYMMDD(endDate);

        setSelectedStartDate(formattedStartDate);
        setSelectedEndDate(formattedEndDate);
        setActiveMonth({ year, monthIndex });
    };

    const fourYears = [];
    for (let index = 0; index < 4; index++) {
        fourYears.push(currentYear - index);
    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const setInvoiceDetailsID = (e: any) => {
        setInvoiceDetailId(e);
    }

    const exchangeProduct = () => {
        navigate("/exchange");
    }
    const returnProduct = () => {
        navigate("/return");
    }

    const handleAddNote = async () => {
        try {
            await useAddInvoiceNote({
                invoice_num: selectedInvoice,
                notes: invoiceNote
            }).unwrap();
            toast.success("Note added successfully!");
            setOpenNoteDialog(false);
            setInvoiceNote('');
            refetch();
        } catch (error) {
            toast.error("Failed to add note!");
        }
    };

    const handlePaymentSubmit = async () => {
        try {
            const paidAmount = Number(paymentDetails.amount);
            const unpaidAmount = paymentDetails.unpaid_amount;
            const today = new Date();
            const formattedDate = today.toLocaleDateString('en-GB').split('/').join('-');

            await useAddInvoicePayment({
                invoice_num: paymentDetails.invoice_num,
                payments: [{
                    payment_mode: paymentDetails.payment_mode,
                    total_amt: unpaidAmount,
                    total_paid_amt: paidAmount,
                    transaction_code: paymentDetails.transaction_code,
                    status: "Paid",
                    desc: paymentDetails.desc,
                    payment_dt: formattedDate,
                    short_amt: 0,
                    created_by: 1
                }]
            }).unwrap();

            toast.success("Payment added successfully!");
            setOpenPaymentDialog(false);
            refetch();
        } catch (error) {
            toast.error("Failed to add payment!");
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleContentPerPageChange = (event: any) => {
        setContentPerPage(event.target.value);
        setPage(1);
    };

    return (
        <>
            <section id="main" className="flex flex-col overflow-auto flex-1 p-3 gap-3">
                <Grid container spacing={2} flexGrow={1}>
                    <Grid item xs={2}>
                        <Box sx={{ ...cardItem, gap: '10px' }}>
                            <ThemeProvider theme={plainAccordion}>
                                {
                                    fourYears.map((year, index) => (
                                        <Accordion defaultExpanded={index === 0} key={index} expanded={expanded === `${"panel" + index}`} onChange={handleChange(`${"panel" + index}`)}>
                                            <AccordionSummary expandIcon={<KeyboardArrowDownOutlined />}
                                                aria-controls={`${'panel' + index + '-content'}`}
                                                id={`${'panel' + index + '-header'}`}
                                            >
                                                {year}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container flexDirection="column" spacing={2}>
                                                    {getMonthNames(year, currentYear, currentMonth).map((month, monthIndex) => (
                                                        <Link
                                                            href="#"
                                                            key={`${year}-${month}-${monthIndex}`}
                                                            className={`${activeMonth.year === year && activeMonth.monthIndex === monthIndex ? 'active' : ''}`}
                                                            onClick={() => handleMonthClick(year, monthIndex)}
                                                        >
                                                            {month}
                                                        </Link>
                                                    ))}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={cardItem}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={4}>
                                    <DynamicSearchBox>
                                        <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                            <OutlinedInput
                                                value={search}
                                                onChange={handleSearch}
                                                type='text'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <Search />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Search"
                                            />
                                        </FormControl >
                                    </DynamicSearchBox>
                                </Grid>
                                <Grid item xs={5} sx={{ marginLeft: 'auto' }}>
                                    <div className='flex items-center gap-2'>
                                        <Grid item>
                                            <ThemeProvider theme={btnTheme}>
                                                <Button
                                                    onClick={() => {
                                                        exchangeProduct();
                                                    }}
                                                    variant="contained">Exchange</Button>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item>
                                            <ThemeProvider theme={btnTheme}>
                                                <Button
                                                    onClick={() => {
                                                        returnProduct();
                                                    }}
                                                    variant="contained">Return</Button>
                                            </ThemeProvider>
                                        </Grid>
                                        <DynamicSearchBox>
                                            <FormControl fullWidth variant="outlined" size='small'>
                                                <InputLabel id="status-label">Select status</InputLabel>
                                                <Select
                                                    labelId="status-label"
                                                    id="status"
                                                    value={statusFilters}
                                                    label="Select status"
                                                    onChange={(e) => handleStatusChange(e.target.value as typeof statusFilters)}
                                                >
                                                    {Object.entries(Status).map(([status, label]) => (
                                                        <MenuItem key={status} value={status}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth variant="outlined" size='small'>
                                                <InputLabel id="content-per-page-label">Content per page</InputLabel>
                                                <Select
                                                    labelId="content-per-page-label"
                                                    id="content-per-page"
                                                    value={contentPerPage}
                                                    label="Content per page"
                                                    onChange={handleContentPerPageChange}
                                                >
                                                    <MenuItem value="10">10</MenuItem>
                                                    <MenuItem value="20">20</MenuItem>
                                                    <MenuItem value="30">30</MenuItem>
                                                    <MenuItem value="40">40</MenuItem>
                                                    <MenuItem value="50">50</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </DynamicSearchBox>
                                    </div>
                                </Grid>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ minWidth: '110px', width: '110px', maxWidth: '110px' }}>Invoice No</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: '150px', width: '150px', maxWidth: '150px' }}>Customer code</StyledTableCell>
                                        {/* <StyledTableCell>Customer</StyledTableCell> */}
                                        <StyledTableCell sx={{ minWidth: '100px', width: '100px', maxWidth: '100px' }}>Contact</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: '100px', width: '100px', maxWidth: '100px' }}>Date</StyledTableCell>
                                        <StyledTableCell>Net total</StyledTableCell>
                                        <StyledTableCell>Paid amount</StyledTableCell>
                                        <StyledTableCell>Unpaid amount</StyledTableCell>
                                        <StyledTableCell>Payment mode</StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="right">&nbsp;</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedInvoices && paginatedInvoices.length > 0 ? (
                                        paginatedInvoices.map((result: any, index: number) => (
                                            <StyledTableRow key={index}>
                                                <TableCell sx={{ minWidth: '110px', width: '110px', maxWidth: '110px' }}>{result.dt_sequence_no}</TableCell>
                                                <TableCell sx={{ minWidth: '150px', maxWidth: '150px', width: '150px', textOverflow: 'clip' }}>
                                                    {result.customer_code}
                                                    <br />
                                                    ({result.customer_name})
                                                </TableCell>
                                                {/* <TableCell></TableCell> */}
                                                <TableCell sx={{ minWidth: '100px', maxWidth: '100px', width: '100px', textOverflow: 'clip' }}>{result.customer_contact}</TableCell>
                                                <TableCell sx={{ minWidth: '100px', maxWidth: '100px', width: '100px', textOverflow: 'clip' }}>{result.invoice_dt}</TableCell>
                                                <TableCell>{result.net_total.toFixed(2)}</TableCell>
                                                <TableCell>{result.paid_amt.toFixed(2)}</TableCell>
                                                <TableCell>{result.unpaid_amt.toFixed(2)}</TableCell>
                                                <TableCell>{result.payment_mode}</TableCell>
                                                <TableCell align="center">
                                                    <Chip label={result.status} size="small" variant="outlined"
                                                        sx={{
                                                            minWidth: '60px',
                                                            fontSize: '10px',
                                                            textTransform: 'uppercase',
                                                            fontWeight: '600',
                                                            letterSpacing: '0.5px',
                                                            lineHeight: '14px',
                                                            borderWidth: '1px',
                                                            borderStyle: '1px solid',
                                                            backgroundColor: result.status == "Paid" ? colorTheme.palette.green50 : result.status == "Unpaid" ? colorTheme.palette.red50 : result.status == "Pending" ? colorTheme.palette.grey50 : colorTheme.palette.amber50,
                                                            borderColor: result.status == "Paid" ? colorTheme.palette.green500 : result.status == "Unpaid" ? colorTheme.palette.red500 : result.status == "Pending" ? colorTheme.palette.grey500 : colorTheme.palette.amber500,
                                                            color: result.status == "Paid" ? colorTheme.palette.green500 : result.status == "Unpaid" ? colorTheme.palette.red500 : result.status == "Pending" ? colorTheme.palette.grey500 : colorTheme.palette.amber500,
                                                        }}
                                                    />

                                                </TableCell>
                                                <TableCell align="right">
                                                    <Grid container spacing={0.1} justifyContent="center">
                                                        <Grid item>
                                                            <Tooltip placement='left' title="View invoice">
                                                                <Button
                                                                    sx={{ padding: 0, border: 0, minWidth: '24px', color: colorTheme.palette.topLink, '&:hover': { border: 0, color: colorTheme.palette.indigo500 }, fontWeight: '600' }}
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        setInvoiceDetailsID(result.dt_sequence_no);
                                                                    }}
                                                                >
                                                                    <VisibilityOutlined />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <PrintButton
                                                                customerId={result.dt_sequence_no}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip placement="right" title={result.notes || "No notes available"}>
                                                                <Button
                                                                    sx={{
                                                                        padding: 0,
                                                                        border: 0,
                                                                        minWidth: '24px',
                                                                        color: colorTheme.palette.topLink,
                                                                        '&:hover': { border: 0, color: colorTheme.palette.indigo500 },
                                                                        fontWeight: '600'
                                                                    }}
                                                                    variant="text"
                                                                >
                                                                    <SpeakerNotes />
                                                                </Button>
                                                            </Tooltip>
                                                            <Tooltip placement="right" title="Add Note">
                                                                <Button
                                                                    sx={{
                                                                        padding: 0,
                                                                        border: 0,
                                                                        minWidth: '24px',
                                                                        color: colorTheme.palette.topLink,
                                                                        '&:hover': { border: 0, color: colorTheme.palette.indigo500 },
                                                                        fontWeight: '600'
                                                                    }}
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        setSelectedInvoice(result.dt_sequence_no);
                                                                        setOpenNoteDialog(true);
                                                                    }}
                                                                >
                                                                    <NoteAdd />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            {/* {(result.status === "Unpaid" || result.status === "Payment Pending" || result.status === "Hold") && ( */}
                                                            <Tooltip placement="right" title="Make Payment">
                                                                <Button
                                                                    sx={{
                                                                        padding: 0,
                                                                        border: 0,
                                                                        minWidth: '24px',
                                                                        color: colorTheme.palette.topLink,
                                                                        '&:hover': { border: 0, color: colorTheme.palette.indigo500 },
                                                                        fontWeight: '600'
                                                                    }}
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        setPaymentDetails({
                                                                            invoice_num: result.dt_sequence_no,
                                                                            unpaid_amount: result.unpaid_amt,
                                                                            payment_mode: 'Cash',
                                                                            amount: '',
                                                                            transaction_code: '',
                                                                            desc: ''
                                                                        });
                                                                        setOpenPaymentDialog(true);
                                                                    }}
                                                                >
                                                                    <Payment />
                                                                </Button>
                                                            </Tooltip>
                                                            {/* )} */}
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </StyledTableRow>
                                        ))
                                    ) : (
                                        <StyledTableRow>
                                            <TableCell colSpan={10} align="center">No records found</TableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>

                            {invoices.length > 0 && (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: '20px 0',
                                    '& .MuiPagination-ul': {
                                        '& .MuiPaginationItem-root': {
                                            '&.Mui-selected': {
                                                backgroundColor: colorTheme.palette.indigo500,
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: colorTheme.palette.indigo500,
                                                }
                                            }
                                        }
                                    }
                                }}>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        color="primary"
                                        shape="rounded"
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid >
            </section>

            {/* Add Note Dialog */}
            <Dialog
                open={openNoteDialog}
                onClose={() => {
                    setOpenNoteDialog(false);
                    setInvoiceNote('');
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add Invoice Note</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Note"
                        fullWidth
                        multiline
                        rows={4}
                        value={invoiceNote}
                        onChange={(e) => setInvoiceNote(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenNoteDialog(false);
                            setInvoiceNote('');
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddNote}
                        color="primary"
                        variant="contained"
                        disabled={!invoiceNote.trim()}
                    >
                        Add Note
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Payment Dialog */}
            <Dialog
                open={openPaymentDialog}
                onClose={() => setOpenPaymentDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Make Payment</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                label="Unpaid Amount"
                                value={paymentDetails.unpaid_amount.toFixed(2)}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Payment Mode</InputLabel>
                                <Select
                                    value={paymentDetails.payment_mode}
                                    label="Payment Mode"
                                    onChange={(e) => setPaymentDetails({
                                        ...paymentDetails,
                                        payment_mode: e.target.value,
                                        transaction_code: ''
                                    })}
                                >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="Credit Card">Card</MenuItem>
                                    <MenuItem value="UPI">UPI</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {paymentDetails.payment_mode === 'Credit Card' && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Transaction Code"
                                    value={paymentDetails.transaction_code}
                                    onChange={(e) => setPaymentDetails({
                                        ...paymentDetails,
                                        transaction_code: e.target.value
                                    })}
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                label="Amount"
                                type="number"
                                value={paymentDetails.amount}
                                onChange={(e) => setPaymentDetails({
                                    ...paymentDetails,
                                    amount: e.target.value
                                })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                value={paymentDetails.desc}
                                onChange={(e) => setPaymentDetails({
                                    ...paymentDetails,
                                    desc: e.target.value
                                })}
                                fullWidth
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPaymentDialog(false)}>Cancel</Button>
                    <Button
                        onClick={handlePaymentSubmit}
                        variant="contained"
                        disabled={!paymentDetails.amount || Number(paymentDetails.amount) <= 0}
                    >
                        Submit Payment
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomerInvoicesTable;