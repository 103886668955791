import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";
import { IBillingTab, IUpdatedProducts } from "../../Types/types";

// const calculateSaleSummary = (products: IUpdatedProducts[]) => {
//     const summary = {
//         subTotal: 0,
//         totalTax: 0,
//         total_items: 0,
//         netTotal: 0,
//         subTotalWoTax: 0,
//         discountValue: 0,
//         MRP: 0,
//         MRPDiscValue: 0,
//     };
//     products.forEach((product) => {
//         summary.subTotal += product.net_total;
//         summary.totalTax += product.tax_amt;
//         summary.netTotal += product.net_total;
//         summary.total_items += product.quantity;
//         summary.subTotalWoTax += product.sub_total;
//         summary.MRP += product.mrp === 0 ? (product.unit_price * product.quantity) : (product.mrp * product.quantity);
//         summary.MRPDiscValue += product.mrp === 0 ? ((product.unit_price * product.quantity) - product.net_total) : ((product.mrp * product.quantity) - product.net_total);
//         summary.discountValue += (product.unit_price * product.quantity) - product.net_total;
//     });
//     return summary;
// };

function roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
}

const calculateProdPrices = (product: IUpdatedProducts) => {
    const prodSummary = {
        subTotal: 0,
        taxAmt: 0,
        netTotal: 0,
        discAmt:0,
        unitPriceWoTax: 0,
    };

    let prodSubTotalInclTax = product.unit_price * product.quantity

    // calculated the discounted values
    if (product.disc_val > 0) {
        if (product.disc_type === "amount") {
            prodSubTotalInclTax = prodSubTotalInclTax - product.disc_val
            prodSummary.discAmt = product.disc_val
        } else {
            const discAmt = (prodSubTotalInclTax * product.disc_val) / 100
            prodSummary.discAmt = discAmt
            prodSubTotalInclTax = prodSubTotalInclTax - discAmt
        }
    } else {
        prodSummary.discAmt = 0
    }

    // calculate the tax on discounted value
    const discountedUnitPriceWTax = prodSubTotalInclTax / product.quantity
    const discountedUnitPriceWoTax = (discountedUnitPriceWTax / (100 + product.tax_rate)) * 100
    prodSummary.unitPriceWoTax = roundToTwoDecimals(discountedUnitPriceWoTax)
    prodSummary.subTotal = roundToTwoDecimals(discountedUnitPriceWoTax * product.quantity)
    const unitTaxAmt = (prodSummary.unitPriceWoTax * product.tax_rate) / 100
    prodSummary.taxAmt = roundToTwoDecimals(unitTaxAmt * product.quantity)
    
    prodSummary.netTotal = roundToTwoDecimals(prodSummary.subTotal + prodSummary.taxAmt)
    
    return prodSummary;
};

const calculateBillSummary = (products: IUpdatedProducts[], discountObj: any) => {
    const summary = {
        subTotal: 0,
        totalTax: 0,
        totalItems: 0,
        netTotal: 0,
        subTotalWoTax: 0,
        discountAmount: 0,
        MRP: 0,
        MRPDiscValue: 0,
    };
    
    const discType = discountObj.type;
    const discVal = discountObj.discount;

    let totalWithoutItemLevelDisc = 0

    products.forEach((product) => {
        if (product.disc_val == 0) {
            totalWithoutItemLevelDisc += product.sub_total
        }
    });
    // console.log("totalWithoutItemLevelDisc : ", totalWithoutItemLevelDisc)
    let discountTotal = 0
    let tSubTotal = 0
    let taxTotal = 0
    let taxDiff = 0

    products.forEach((product) => {
        
        const itemDiscountedSubTotal = product.sub_total
        if (product.disc_val == 0) {
            if (discType == "amount") {
                const billDiscBeforeTax = ((discVal / (100 + product.tax_rate)) * 100)
                const itemAmtPerc = (itemDiscountedSubTotal / totalWithoutItemLevelDisc) * 100
                const discount_amt = (billDiscBeforeTax * itemAmtPerc) / 100
                discountTotal = discountTotal + discount_amt
                tSubTotal = tSubTotal + itemDiscountedSubTotal - discount_amt
                const taxAmount = ((itemDiscountedSubTotal - discount_amt) * product.tax_rate) / 100
                taxTotal = taxTotal + taxAmount
                taxDiff += (product.tax_amt - taxAmount)
            }

            if (discType == "percent") {
                const discount_amt = (itemDiscountedSubTotal * discVal) / 100
                discountTotal = discountTotal + discount_amt
                tSubTotal = tSubTotal + itemDiscountedSubTotal - discount_amt
                const taxAmount = ((itemDiscountedSubTotal - discount_amt) * product.tax_rate) / 100
                taxTotal = taxTotal + taxAmount
                taxDiff += (product.tax_amt - taxAmount)
            }
        } else {
            tSubTotal = tSubTotal + itemDiscountedSubTotal
            discountTotal = discountTotal + 0
            const taxAmount = product.tax_amt
            taxTotal = taxTotal + taxAmount
            taxDiff += (product.tax_amt - taxAmount)
        }

        summary.totalItems += product.quantity;
        summary.MRP += product.mrp === 0 ? (product.unit_price * product.quantity) : (product.mrp * product.quantity);
        summary.MRPDiscValue += product.mrp === 0 ? ((product.unit_price * product.quantity) - product.net_total) : ((product.mrp * product.quantity) - product.net_total);
        summary.subTotal += product.net_total;
    
    });

    summary.MRPDiscValue = summary.MRPDiscValue + discountTotal + taxDiff
    summary.totalTax = taxTotal;
    summary.netTotal = tSubTotal + taxTotal;
    summary.subTotalWoTax = tSubTotal;
    summary.discountAmount += discountTotal + taxDiff;

    return summary;
};

const BillingTabData = () => {
    const BillingTabDetails = JSON.parse(localStorage.getItem("BillingTabState") || '[]') as IBillingTab[];
    let intiState: IBillingTab[] = [{
        id: nanoid(10),
        billingDetail: {
            selectedProducts: [],
            saleSummary: {
                subTotal: 0,
                subTotalWoTax: 0,
                discount: 0,
                totalTax: 0,
                total_items: 0,
                netTotal: 0,
                billDiscType: "percent",
                discountValue: 0,
                MRP: 0,
                MRPDiscValue: 0,
            },
            productDiscounts: {},
            productUnitPrice: {},
            originalUnitPrice: {},
            discount: {
                discount: 0,
                type: 'percent'
            },
            customerDetails: {
                billNo: '',
                contact_name_1: '',
                contact_no_1: ''
            }
        }
    }];
    if (BillingTabDetails.length > 0) {
        intiState = BillingTabDetails;
    }
    return intiState;
}

const initialState: IBillingTab[] = [{
    id: nanoid(10),
    billingDetail: {
        selectedProducts: [],
        saleSummary: {
            subTotal: 0,
            subTotalWoTax: 0,
            discount: 0,
            totalTax: 0,
            total_items: 0,
            netTotal: 0,
            billDiscType: "percent",
            discountValue: 0,
            MRP: 0,
            MRPDiscValue: 0,
        },
        productDiscounts: {},
        productUnitPrice: {},
        originalUnitPrice: {},
        discount: {
            discount: 0,
            type: 'percent'
        },
        customerDetails: {
            billNo: '',
            contact_name_1: '',
            contact_no_1: ''
        }
    }
}];

type TAddProduct = {
    tabIndex: number,
    product: IUpdatedProducts
}

type TProductQuantityUpdate = {
    tabIndex: number,
    product: IUpdatedProducts,
    actionType: "increment" | "decrement"
}

const initialStateDetails = BillingTabData()

const billingsTabSlice = createSlice({
    name: 'billingsTab',
    initialState: initialStateDetails,
    reducers: {
        addBillingTab: (state) => {
            const value: IBillingTab = {
                id: nanoid(10),
                billingDetail: { ...initialState[0].billingDetail },
            }
            state.push(value);
        },
        removeBillingTab: (state, action: PayloadAction<{ index: number }>) => {
            const newState = state.filter((_, index) => index !== action.payload.index);
            localStorage.setItem('BillingTabState', JSON.stringify(newState));

            if (newState.length === 0) {
                return [
                    {
                        id: nanoid(10),
                        billingDetail: { ...initialState[0].billingDetail },
                    }
                ];
            }

            return newState;

        },
        addCustomerToBilling: (state, action: PayloadAction<{ tabIndex: any, customerName: any; customerPhone: any, billNo: any }>) => {
            const { tabIndex, customerName, customerPhone, billNo } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) {
                return;
            }
            const { billingDetail } = billingTab;
            billingDetail.customerDetails.contact_name_1 = customerName;
            billingDetail.customerDetails.contact_no_1 = customerPhone;
            billingDetail.customerDetails.billNo = billNo;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        addProductsToBilling: (state, action: PayloadAction<TAddProduct>) => {
            const { tabIndex, product } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;
            const { billingDetail } = billingTab;

            const existingProduct = billingDetail.selectedProducts.find(p => p.barCode === product.barCode);
            console.log("addProductsToBilling called")
            if (existingProduct) {
                existingProduct.quantity += 1;
                const productSummaryData = calculateProdPrices(existingProduct)

                existingProduct.sub_total = productSummaryData.subTotal
                existingProduct.disc_amt = productSummaryData.discAmt
                existingProduct.tax_amt = productSummaryData.taxAmt
                existingProduct.net_total = productSummaryData.netTotal
                existingProduct.unitPriceWoTax = productSummaryData.unitPriceWoTax

                // existingProduct.sub_total = (existingProduct.unit_price * existingProduct.quantity) / (1 + existingProduct.tax_rate / 100);
                // existingProduct.sub_total_disc = existingProduct.sub_total_disc + existingProduct.unit_price;
                // existingProduct.tax_amt = (existingProduct.sub_total * existingProduct.tax_rate) / 100;
                // existingProduct.net_total = existingProduct.sub_total + existingProduct.tax_amt;
            } else {
                const productSummaryData = calculateProdPrices(product)

                product.sub_total = productSummaryData.subTotal
                product.disc_amt = productSummaryData.discAmt
                product.tax_amt = productSummaryData.taxAmt
                product.net_total = productSummaryData.netTotal
                product.unitPriceWoTax = productSummaryData.unitPriceWoTax
                // product.net_total = product.unit_price;
                // product.unitPriceWoTax = (product.net_total / (100 + product.tax_rate)) * 100;
                // product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                billingDetail.selectedProducts.unshift(product);
                billingDetail.originalUnitPrice[product.barCode] = { unti_price: product.unit_price };
            }

            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        updateProductQuantityToBilling: (state, action: PayloadAction<TProductQuantityUpdate>) => {

            const { tabIndex, product: selectedProduct } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            // const product = billingDetail.selectedProducts.find((product) => product.barCode === selectedProduct.barCode);
            // if (!product) {
            //     return;
            // }
            // const originalQuantity = product.quantity;
            
            
            billingDetail.selectedProducts.forEach((product) => {
                if (product.barCode === selectedProduct.barCode) {

                    if (action.payload.actionType === "increment") {
                        product.quantity += 1;
                    } else if (action.payload.actionType === "decrement" && product.quantity > 1) {
                        product.quantity -= 1;
                    }

                    const productSummaryData = calculateProdPrices(product)

                    product.sub_total = productSummaryData.subTotal
                    product.disc_amt = productSummaryData.discAmt
                    product.tax_amt = productSummaryData.taxAmt
                    product.net_total = productSummaryData.netTotal
                    product.unitPriceWoTax = productSummaryData.unitPriceWoTax
                    // product.unit_price = unit_price;
                    // // product.quantity = 1;
                    // product.sub_total_disc = product.unit_price * product.quantity;
                    // product.net_total = product.unit_price * product.quantity;
                    // product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
                    // product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                }
            });

            // if (product.quantity !== originalQuantity) {
            //     product.sub_total = (product.unit_price * product.quantity) / (1 + product.tax_rate / 100);
            //     product.tax_amt = (product.sub_total * product.tax_rate) / 100;
            //     product.net_total = product.sub_total + product.tax_amt;
            // }

            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        updateUnitPriceToBilling: (state, action: PayloadAction<{ productData: any; unit_price: number, tabIndex: number }>) => {
            const { productData, unit_price, tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            const originalUnitPrice = billingDetail.originalUnitPrice[productData.barCode].unti_price

            
            billingDetail.productUnitPrice[productData.barCode] = { unti_price: unit_price };

            billingDetail.selectedProducts.forEach((product) => {
                if (product.barCode === productData.barCode) {
                    
                    if (originalUnitPrice != unit_price) {
                        product.disc_type = "amount"
                        product.disc_val = 0
                        billingDetail.productDiscounts[product.barCode] = { disc_val: 0, disc_type: "amount" };
                    }

                    product.unit_price = unit_price;
                    const productSummaryData = calculateProdPrices(product);

                    product.sub_total = productSummaryData.subTotal;
                    product.disc_amt = productSummaryData.discAmt;
                    product.tax_amt = productSummaryData.taxAmt;
                    product.net_total = productSummaryData.netTotal;
                    product.unitPriceWoTax = productSummaryData.unitPriceWoTax;
                    // product.unit_price = unit_price;
                    // // product.quantity = 1;
                    // product.sub_total_disc = product.unit_price * product.quantity;
                    // product.net_total = product.unit_price * product.quantity;
                    // product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
                    // product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                }
            });
            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        updateProductTaxDetailsToBilling: (state, action: PayloadAction<{ barCode: string; newTaxDetails: any, tabIndex: number }>) => {
            const { barCode, newTaxDetails, tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab

            const product = billingDetail.selectedProducts.find((product) => product.barCode === barCode);
            if (product) {
                product.tax_rate = newTaxDetails.tax_rate;
                // product.net_total = product.unit_price;
                // product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
                product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                product.tax_rate_name = newTaxDetails.tax_rate_name;
            }
            // const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            // billingDetail.saleSummary.subTotal = summaryData.subTotal;
            // billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            // billingDetail.saleSummary.totalTax = summaryData.totalTax;
            // billingDetail.saleSummary.netTotal = summaryData.netTotal;
            // billingDetail.saleSummary.total_items = summaryData.totalItems;
            // billingDetail.saleSummary.MRP = summaryData.MRP;
            // billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            // billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        onUpdatediscountPriceToBilling: (state, action: PayloadAction<{ discount: number; type: string; tabIndex: number }>) => {
            const { tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab;

            billingDetail.saleSummary.discount = action.payload.discount;
            billingDetail.saleSummary.billDiscType = action.payload.type;
            // console.log("selected product..", billingDetail.selectedProducts)

            // console.log("disc typeB : ", billingDetail.saleSummary.billDiscType, " : Disc ValB : ", billingDetail.saleSummary.discount)
            billingDetail.discount.discount = action.payload.discount;
            billingDetail.discount.type = action.payload.type;


            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        onUpdateProductDiscountPriceToBilling: (state, action: PayloadAction<{ barCode: any; disc_val: any; disc_type: any; tabIndex: number }>) => {
            const { tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;
            const { billingDetail } = billingTab

            const disc_val = action.payload.disc_val;
            const disc_type = action.payload.disc_type;

            // if (disc_val == 0) {
            //     return;
            // }

            billingDetail.productDiscounts[action.payload.barCode] = { disc_val: action.payload.disc_val, disc_type: action.payload.disc_type };
            const product = billingDetail.selectedProducts.find((p) => p.barCode === action.payload.barCode);
            if (!product) {
                return;
            }
            // const discount = billingDetail.productDiscounts[product.barCode];
            // const disc_val = discount ? discount.disc_val : 0;
            // const disc_type = discount ? discount.disc_type : 'percent';
            
            console.log("disc Type ", disc_type, " : Disc Val ", disc_val);
            product.disc_type = disc_type
            product.disc_val = disc_val

            const productSummaryData = calculateProdPrices(product)

            product.sub_total = productSummaryData.subTotal
            product.disc_amt = productSummaryData.discAmt
            product.tax_amt = productSummaryData.taxAmt
            product.net_total = productSummaryData.netTotal
            product.unitPriceWoTax = productSummaryData.unitPriceWoTax

            // product.sub_total = ((product.unit_price * product.quantity) / (100 + product.tax_rate)) * 100;
            // if (disc_val === 0) {
            //     delete billingDetail.productDiscounts[action.payload.barCode];
            //     if (billingDetail.selectedProducts[action.payload.barCode] && 'disc_val' in billingDetail.selectedProducts[action.payload.barCode]) {
            //         const { disc_val, ...rest } = billingDetail.selectedProducts[action.payload.barCode];
            //         console.log("🚀 ~ disc_val:", disc_val)
            //         billingDetail.selectedProducts[action.payload.barCode] = { ...rest };
            //     }
            //     localStorage.setItem('BillingTabState', JSON.stringify(state));

            // } else {
            //     billingDetail.productDiscounts[action.payload.barCode] = { disc_val, disc_type };
            // }
            // const originalUnitPrice = billingDetail.originalUnitPrice[action.payload.barCode];
            // if (originalUnitPrice && product.unit_price === originalUnitPrice.unti_price) {
            //     if (disc_type === 'percent') {
            //         const discountCalculation = 1 - (disc_val / 100);
            //         product.sub_total *= discountCalculation;
            //         product.tax_amt = (product.sub_total * product.tax_rate) / 100;
            //         product.net_total = product.sub_total + product.tax_amt;
            //         product.disc_type = disc_type;
            //         product.disc_val = disc_val;
            //     } else if (disc_type === 'amount') {
            //         product.sub_total = (product.unit_price * product.quantity) - disc_val;
            //         product.tax_amt = (product.sub_total * product.tax_rate) / 100;
            //         product.net_total = product.sub_total;
            //         product.disc_type = disc_type;
            //         product.disc_val = disc_val;
            //     }
            //     // product.tax_amt = (product.sub_total * product.tax_rate) / 100;
            //     // product.net_total = product.sub_total + product.tax_amt;
            //     // product.disc_type = disc_type;
            //     // product.disc_val = disc_val;
            // }
            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        setProductsOnInitToBilling: (state, action: PayloadAction<{ product: IUpdatedProducts[]; tabIndex: number }>) => {
            const { tabIndex, product } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            billingDetail.selectedProducts = product;
            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
        },
        removeProductToBilling: (state, action: PayloadAction<{ barCode: string; tabIndex: number }>) => {
            const { tabIndex, barCode } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            billingDetail.selectedProducts = billingDetail.selectedProducts.filter((product) => product.barCode !== action.payload.barCode);
            delete billingDetail.productDiscounts[barCode];
            delete billingDetail.productUnitPrice[barCode];
            delete billingDetail.originalUnitPrice[barCode];

            const summaryData = calculateBillSummary(billingDetail.selectedProducts, billingDetail.discount)
            
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalWoTax = summaryData.subTotalWoTax;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.totalItems;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountAmount;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        resetState: () => initialState,
    }
});

export const billingsAction = billingsTabSlice.actions;
export default billingsTabSlice.reducer;