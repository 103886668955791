import { Button, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import { DeleteOutline, Search } from "@mui/icons-material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PaymentModal from "./PaymentModal";
import FeedbackModal from "./FeedbackModal";
import PrintButtons from "./PrintButtons";
import { useGetProductTaxMutation, useGetProductsQuery } from "../../Shared/Store/Api/productApi";
import { useCreateInvoiveMutation } from "../../Shared/Store/Api/invoiceApi";
import { IBillingTab, IMasterTax, TMasterTaxList, TPayment } from "../../Shared/Types/types";
import { cardItem, colorTheme, labelItem, transactionBtn, paymentModes } from "../../Common/Theme";
import Customers from "../../Components/Billing/Customers";
import { ThemeProvider } from '@mui/material/styles';
import cash from '../../Assets/images/cash.png';
import upi from '../../Assets/images/upi.png';
import multiModes from '../../Assets/images/multi-modes.png';
import creditCard from '../../Assets/images/credit-card.png';
// import holdOrder from '../../Assets/images/hold-order.png';
import DynamicSearchBox from "../../Common/DynamicSearchBox";
import { billingsAction } from "../../Shared/Store/Slice/ProductList";


interface TProps {
    tableIndex: number,
    billing: IBillingTab
}

function roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
}

function ProductsTable(props: TProps) {
    const { billing, tableIndex } = props;
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const [customDate, setCustomDate] = useState(`${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`);


    const formattedDate = new Date(customDate);
    const formattedDateDay = formattedDate.getDate();
    const formattedDateMonth = formattedDate.getMonth() + 1;
    const formattedDateYear = formattedDate.getFullYear();
    const formattedFullDate = `${formattedDateDay < 10 ? '0' + formattedDateDay : formattedDateDay}-${formattedDateMonth < 10 ? '0' + formattedDateMonth : formattedDateMonth}-${formattedDateYear}`


    const [customerDetails, setCustomerDetails] = useState({
        name: billing.billingDetail.customerDetails.contact_name_1 || "Walk-in Customer",
        mobile: billing.billingDetail.customerDetails.contact_no_1 || ""
    });

    const handleSetCustomerDetails = (details: any) => {
        setCustomerDetails(details);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: '20px',
        gap: '15px',
    }));

    const genrateBillNo = () => {
        const today = new Date();
        const year = today.getFullYear().toString().slice(-2);
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const todayDate = `${day < 10 ? + day : day}${month < 10 ? + month : month}${year}`;
        const randomDigit = Math.floor(Math.random() * 10);
        setTempBillNo(`${todayDate}${randomDigit}`);
    };


    const [searchQuery, setSearchQuery] = useState('');
    const { data: products, refetch } = useGetProductsQuery({ search: searchQuery });
    const { selectedProducts, saleSummary, productDiscounts } = billing.billingDetail;
    const dispatch = useDispatch();
    const [getTax] = useGetProductTaxMutation();
    const [createInvoice] = useCreateInvoiveMutation();
    const [customerName, setCustomerName] = useState(billing.billingDetail.customerDetails.contact_name_1 || "");
    const [customerMobile, setCustomerMobile] = useState(billing.billingDetail.customerDetails.contact_name_1 || "");
    const [tempBillNo, setTempBillNo] = useState('')
    const [billNumber, setBillNumber] = useState(billing.billingDetail.customerDetails.billNo || tempBillNo);
    const [type, setType] = useState('amount');
    const [productType, setProductType] = useState('amount');
    const [productdiscVal, setProductDiscVal] = useState(0);
    const [paymentAmt, setPaymentAmt] = useState(0);
    const [transactionCode, setTransactionCode] = useState('');
    const [paymentAmtBtn, setPaymentAmtBtn] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [feedbackModalTitle, setFeedbackModalTitle] = useState('');
    const [payments, setPayments] = useState<TPayment[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [halfPaymentValue, setHalfPaymentValue] = useState(false);
    const isDisabled = selectedProducts.length === 0;

    const searchProduct = useRef<HTMLDivElement>(null);

    const handleCloseModal = () => setOpenModal(false);

    const halfPaymentHandle = (halfPayment: boolean) => {
        setHalfPaymentValue(halfPayment);
    }

    const handleSetPayment = (types: string, typesValue: boolean) => {
        if (typesValue === true) {
            setPayments((payments: any) => [
                ...payments,
                {
                    "payment_mode": types,
                    "total_amt": saleSummary.netTotal,
                    "short_amt": 0,
                    "total_paid_amt": paymentAmt,
                    "transaction_code": transactionCode || "",
                    "status": "Partial Payment",
                    "desc": "",
                    "payment_dt": formattedFullDate
                }
            ]);
        }
        else {
            setPayments((payments: any) =>
                payments.filter((payment: any) => payment.payment_mode !== types)
            );
        }
    }

    const paymnetHandler = (e: any, types: string) => {
        setPaymentAmt(parseFloat(e.target.value));
        setPaymentAmtBtn(true);
        const payment = payments.find((payment: any) => payment.payment_mode === types);
        if (payment) {
            setPayments((payments: any) =>
                payments.map((paymnet: any) =>
                    paymnet.payment_mode === types
                        ? { ...paymnet, total_paid_amt: parseFloat(e.target.value) }
                        : paymnet
                )
            );
        }
    }

    const transactionCodeHandler = (e: any) => {
        setTransactionCode(e.target.value);
    }

    const handleOpen = (title: string) => {
        setModalTitle(title);
        setOpen(true);
    };

    const handleClose = () => {
        setPaymentAmt(0);
        setPaymentAmtBtn(false);
        setTransactionCode('');
        setPayments([]);
        setOpen(false);
        setOpenModal(false);
    };

    useEffect(() => {
        if (searchQuery) {
            refetch();
        }
    }, [searchQuery, refetch]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (searchProduct.current && !searchProduct.current.contains(event.target)) {
                setSearchQuery('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchProduct, setSearchQuery]);

    useEffect(() => {
        if (products && products.length === 1) {
            handleProductClick(products[0]);
        }
    }, [products]);

    useEffect(() => {
        genrateBillNo();
        if (tempBillNo) {
            setBillNumber(tempBillNo);
        } else {
            setBillNumber(billing.billingDetail.customerDetails.billNo || "")
        }
        setCustomerName(billing.billingDetail.customerDetails.contact_name_1 || "Walk-in Customer");
        setCustomerMobile(billing.billingDetail.customerDetails.contact_no_1 || "");

    }, [customerDetails, tempBillNo]);

    useEffect(() => {
        const products = billing.billingDetail.selectedProducts;
        if (products.length > 0) {
            dispatch(billingsAction.setProductsOnInitToBilling({ product: products, tabIndex: tableIndex }));
        }
        const discount = billing.billingDetail.discount;
        if (!discount) {
            dispatch(billingsAction.onUpdatediscountPriceToBilling({ type: billing.billingDetail.saleSummary.billDiscType, discount: billing.billingDetail.saleSummary.discount, tabIndex: tableIndex }));
        } else {
            const discountData = discount;
            dispatch(billingsAction.onUpdatediscountPriceToBilling({ type: discountData.type, discount: discountData.discount, tabIndex: tableIndex }));
        }
        const selectedProductDiscounts = billing.billingDetail.productDiscounts;
        Object.entries<{ disc_val: number; disc_type: string }>(selectedProductDiscounts).forEach(([barCode, discountData]) => {
            dispatch(billingsAction.onUpdateProductDiscountPriceToBilling({ barCode, disc_val: discountData.disc_val, disc_type: discountData.disc_type, tabIndex: tableIndex }));
        });

    }, [dispatch, billing.billingDetail.saleSummary.discount, billing.billingDetail.saleSummary.billDiscType]);


    const adjustShortAmount = () => {
        const totalPaidAmount = payments.reduce((acc, payment) => acc + payment.total_paid_amt, 0);
        const totalShortAmount = saleSummary.netTotal - totalPaidAmount;
        if (payments.length > 0) {
            setPayments((payments: any) =>
                payments.map((payment: any, index: number) => ({
                    ...payment,
                    short_amt: halfPaymentValue ? 0 : index === 0 ? totalShortAmount : 0
                }))
            );
        }
    }

    useEffect(() => {
        adjustShortAmount();
    }, [payments, saleSummary.netTotal, halfPaymentValue, adjustShortAmount]);

    // table products increment and decrement handler

    const increment = (product: any) => {
        dispatch(billingsAction.updateProductQuantityToBilling({ product, actionType: "increment", tabIndex: tableIndex }))

        // if (product.quantity < product.quantity_at_hand) {
        // } else {
        //     toast.error("Maximum quantity reached");
        // }
    };

    const decrement = (product: any) => {
        dispatch(billingsAction.updateProductQuantityToBilling({ product, actionType: "decrement", tabIndex: tableIndex }))
    };

    // discoutn change handler

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
        dispatch(billingsAction.onUpdatediscountPriceToBilling({ discount: 0, type: event.target.value, tabIndex: tableIndex }))
    };

    const discountHandler = (e: any) => {
        dispatch(billingsAction.onUpdatediscountPriceToBilling({ discount: e.target.value, type: type, tabIndex: tableIndex }))
    }

    const productDisTypeChange = (e: SelectChangeEvent, product: any) => {
        setProductType(e.target.value as string);
        const disc_val = productDiscounts[product.barCode] ? productDiscounts[product.barCode].disc_val : 0;
        billing.billingDetail.selectedProducts.forEach(prod => {
            if (prod.prodBarCode === product.prodBarCode) {
                dispatch(billingsAction.onUpdateProductDiscountPriceToBilling({ barCode: prod.barCode, disc_val: disc_val, disc_type: e.target.value, tabIndex: tableIndex }))
            }
        });
    };

    const productDiscountHandler = (e: ChangeEvent<HTMLInputElement>, product: any) => {
        const discountValue = parseFloat(e.target.value) || 0
        setProductDiscVal(parseFloat(e.target.value));
        console.log("disc Type11 ", productType, " : Disc Val11 ", discountValue);
        billing.billingDetail.selectedProducts.forEach(prod => {
            if (prod.barCode === product.barCode) {
                dispatch(billingsAction.onUpdateProductDiscountPriceToBilling({ barCode: prod.barCode, disc_val: discountValue, disc_type: productType, tabIndex: tableIndex }));
            }
        });
        // dispatch(billingsAction.onUpdateProductDiscountPriceToBilling({ barCode: product.barCode, disc_val: discountValue, disc_type: productType, tabIndex: tableIndex }))
        if (discountValue === 0) {
            discountHandler({ target: { value: billing.billingDetail.saleSummary.discount } });
        }
    };

    // on search hadler

    const handleSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    };


    const handleUnitPriceChange = async (event: React.ChangeEvent<HTMLInputElement>, product: any) => {
        const inputValue = event.target.value;
        const unit_price = parseFloat(inputValue);

        if (inputValue === '') {
            // dispatch(billingsAction.updateProductTaxDetailsToBilling({ barCode: product.barCode, newTaxDetails: {}, tabIndex: tableIndex }));
            // dispatch(billingsAction.updateUnitPriceToBilling({ productData: product, unit_price: 0, tabIndex: tableIndex }));
            return;
        }

        if (isNaN(unit_price)) return;

        // let priceDiff = product.mrp - unit_price
        // if (priceDiff < 0) {
        //     priceDiff = priceDiff * (-1)
        // }
        // const perctDiff = ((priceDiff / product.mrp) * 100)

        // if (perctDiff > 50) return;

        // const productDetails = {
        //     price: unit_price,
        //     place_of_supply: "24",
        //     hsn_code: product.hsn_code,
        //     prod_sku: product.prod_sku,
        // };

        try {
            const mstTaxesData: TMasterTaxList = JSON.parse(localStorage.getItem("mstTaxesData") || "[]")
            
            // if (mstTaxesData != null) {
            //     const lstTaxes = mstTaxesData['allTaxes']

            //     lstTaxes.forEach((tax) => {
            //         console.log(tax)
            //     });
            // }

            // const allTaxes = mstTaxesData.allTaxes

            // console.log(mstTaxesData)
            let newTaxDetails = {}
            
            mstTaxesData.forEach((t: IMasterTax) => {
                // console.log("t : ", t)
                // const parsedTaxes: IMasterTax = t;

                // console.log("t.hsnCode : ", t.hsnCode)
                if (t.homeState && t.hsnCode == product.hsn_code) {
                    // console.log("t group : ", t)
                    if (t.minVal <= unit_price && t.maxVal >= unit_price) {
                        newTaxDetails = {
                            tax_rate: t.taxRate,
                            tax_rate_name: t.taxName,
                            hsn_tax: t.hsnTaxId,
                        };
                    }
                }
                
            });
            // try {
            //     const jsonStringArray: string[] = JSON.parse(mstTaxesData);
            //     const parsedPeople: IMasterTax[] = jsonStringArray
            //       .map((item) => {
            //         try {
            //           return JSON.parse(item) as IMasterTax;
            //         } catch (err) {
            //           console.error('Error parsing item:', item);
            //           return null;
            //         }
            //       })
            //       .filter((person): person is IMasterTax => person !== null);
                  
            //     console.log(parsedPeople)
            //     // setPeople(parsedPeople);
            //   } catch (error) {
            //     console.error('Error parsing peopleData from localStorage:', error);
            //   }
            

            
            
            // const response = await getTax(productDetails).unwrap();
            // const newTaxDetails = {
            //     tax_rate: response.tax_rate,
            //     tax_rate_name: response.tax_rate_name,
            //     tax_amt: response.tax_amt,
            //     net_total: response.total_amt_before_tax,
            //     hsn_tax: response.hsn_tax,
            // };
            dispatch(billingsAction.updateProductTaxDetailsToBilling({ barCode: product.barCode, newTaxDetails, tabIndex: tableIndex }));
            dispatch(billingsAction.updateUnitPriceToBilling({ productData: product, unit_price, tabIndex: tableIndex }));
        
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong!");
        }
    };


    // Product Add to Table and State Slice Handler

    const handleProductClick = async (product: any) => {
        setSearchQuery('');
        dispatch(billingsAction.onUpdatediscountPriceToBilling({ discount: 0, type: 'amount', tabIndex: tableIndex }))
        const productDetails = {
            price: product.avg_selling_rate,
            place_of_supply: "24",
            hsn_code: product.hsn_id,
            prod_sku: product.prod_sku,
        };
        console.log("product price....", product.avg_selling_rate)
        try {
            await getTax(productDetails).unwrap().then((res: any) => {
                const prodTaxData = res;
                if (prodTaxData && productDetails.prod_sku === product.prod_sku) {
                    const disc_type = productDiscounts[product.barCode]?.disc_type || "";
                    let prodDisc_val = 0;
                    if (productdiscVal === 0) {
                        prodDisc_val = productDiscounts[product.barCode]?.disc_val;
                    } else {
                        prodDisc_val = 0;
                    }

                    const updatedProductItem = {
                        "prod_title": product.prod_title,
                        "barCode": product.barCode,
                        "quantity_at_hand": product.quantity_at_hand,
                        "prod_sku": product.prod_sku,
                        "hsn_code": product.hsn_id,
                        "unit_price": product.avg_selling_rate,
                        "quantity": 1,
                        "mrp": product.mrp,
                        "sub_total": product.avg_selling_rate,
                        "disc_type": disc_type,
                        "disc_val": prodDisc_val || 0,
                        "sub_total_disc": 0,
                        "tax_rate": prodTaxData.tax_rate,
                        "tax_rate_name": prodTaxData.tax_rate_name,
                        "tax_amt": prodTaxData.tax_amt,
                        "net_total": product.avg_selling_rate,
                        "hsn_tax": prodTaxData.hsn_tax,
                        "size": product.size,
                        "colour": product.color,
                        "discount_flag": product.discount_flag,
                        "prodBarCode": product.prodBarCode,
                        "unitPriceWoTax": 0,
                        "disc_amt": 0
                    };
                    dispatch(billingsAction.addProductsToBilling({
                        tabIndex: tableIndex,
                        product: updatedProductItem
                    }))
                }
            });
        } catch (error) {
            toast.error("Something went wrong!");
        }
    };

    // Product Remove From Table Handler

    const handleProductRemove = (barCode: any) => {
        dispatch(billingsAction.removeProductToBilling({ barCode, tabIndex: tableIndex }));
    };
    const adjustedShortAmt = Math.round(saleSummary.netTotal) - paymentAmt;

    const createInvoiceHandler = async () => {
        const walkinCustomer = !customerName && !customerMobile;
        const paymentMode = modalTitle === 'Card' ? 'Credit Card' : modalTitle;
        const isPartialPayment = modalTitle === 'Multi Modes';
        const invoiceData = {
            "walkin_customer": walkinCustomer ? "True" : "False",
            "temporary_bill": billNumber ? Number(billNumber) : 0,
            "sub_total": roundToTwoDecimals(saleSummary.subTotalWoTax),
            "disc_type": billing.billingDetail.saleSummary.billDiscType,
            "disc_val": billing.billingDetail.saleSummary.discount,
            "disc_amt": roundToTwoDecimals(billing.billingDetail.saleSummary.discountValue),
            "sub_total_disc": 0,
            "total_tax_amt": roundToTwoDecimals(saleSummary.totalTax),
            "net_total": roundToTwoDecimals(saleSummary.netTotal),
            "total_items": saleSummary.total_items,
            "invoice_status": "Order Completed",
            "invoice_dt": formattedFullDate,
            "due_dt": formattedFullDate,
            "place_of_supply": "24",
            "customer": {
                "cutomer_name": customerName || "",
                "customer_contact": customerMobile || ""
            },
            "products": selectedProducts,
            "payments": isPartialPayment ? payments : [{
                "payment_mode": paymentMode,
                "total_amt": roundToTwoDecimals(saleSummary.netTotal),
                "short_amt": halfPaymentValue ? 0 : adjustedShortAmt,
                "total_paid_amt": roundToTwoDecimals(paymentAmt),
                "transaction_code": transactionCode || "",
                "status": "Paid",
                "desc": "",
                "payment_dt": formattedFullDate
            }]
        }

        try {
            await createInvoice(invoiceData).unwrap().then((res) => {
                if (res) {
                    setBillNumber(res.data.dt_sequence_no);
                }
                setOpen(false);
                setOpenModal(true);
                setFeedbackModalTitle('Transaction successfull!');

            })
        } catch (error: any) {
            setOpenModal(true);
            toast.error(error?.data?.non_field_errors[0]);
            setFeedbackModalTitle('Transaction unsuccessfull!');
        }
    }

    const paymentSuccessfullClose = () => {
        setOpenModal(false)
        const billingTabState = JSON.parse(localStorage.getItem("BillingTabState") || '[]');
        const updatedState = billingTabState.filter((_: any, index: any) => index !== tableIndex);
        localStorage.setItem("BillingTabState", JSON.stringify(updatedState));
        dispatch(billingsAction.removeBillingTab({ index: tableIndex }));
        genrateBillNo();
        setCustomerName("");
        setCustomerMobile("");
        setBillNumber("");
        refetch();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.altKey) {
            switch (event.key) {
                case 'c':
                    handleOpen('Cash');
                    break;
                case 'q':
                    handleOpen('Card');
                    break;
                case 'u':
                    handleOpen('UPI');
                    break;
                case 'm':
                    handleOpen('Multi Modes');
                    break;
                case 'r':
                    paymentSuccessfullClose();
                    break;
                default:
                    break;
            }
        }
    };


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // const isProductSelected = (product: any) => {
    //     return selectedProducts.some((selectedProduct: any) => selectedProduct.barCode === product.barCode);
    // };

    return (
        <>
            <Grid item xs={6} md={4} sx={{ paddingTop: '0px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ ...cardItem, overflow: 'visible', padding: '10px' }}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={12}>
                                    <Customers tabIndex={tableIndex} setCustomerDetails={handleSetCustomerDetails} />
                                </Grid>
                                <Grid item xs={12}>
                                    <table className="w-full customTable mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Bill no</th>
                                                <th className="text-left">Bill date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{billNumber}</td>
                                                <td><input type="date" value={customDate} className="discountInp date" placeholder="DD/MM/YYYY"
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        setCustomDate(e.target.value)
                                                    }}
                                                /></td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th className="text-left">Customer name</th>
                                                <th className="text-left">Contact no.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{customerName}</td>
                                                <td>{customerMobile}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="customPadd">
                        <Box sx={{ ...cardItem, padding: '10px' }}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 600, color: colorTheme.palette.primary.main }}>Sale summary</Typography>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={labelItem}>Total no. of Items</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>{saleSummary.total_items} pcs</Grid> */}
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={labelItem}>Sub Total <br/>(tax inclusive)</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>{parseFloat(saleSummary.subTotal.toString()).toFixed(2) || 0}</Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={labelItem}>Discount Price</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={3}>
                                            <input type="text" value={billing.billingDetail.saleSummary.discount} onChange={discountHandler} placeholder="20" className='discountInp' />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth variant='standard'>
                                                <Select
                                                    labelId="discount-type"
                                                    id="demo-simple-select"
                                                    value={billing.billingDetail.saleSummary.billDiscType ? billing.billingDetail.saleSummary.billDiscType : 'amount'}
                                                    label="Type"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value='amount' selected>flat</MenuItem>
                                                    <MenuItem value='percent'>%</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    borderBottom: '1px solid #000',
                                    width: 'calc(100% + 40px)',
                                    flex: 'calc(100% + 40px)',
                                    maxWidth: 'calc(100% + 40px)',
                                    minWidth: 'calc(100% + 40px)',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    margin: '0 -20px 0 -12px',
                                }}></Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>Net Total</Typography>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={7}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>₹&nbsp;
                                        {Math.round(parseFloat(saleSummary.netTotal.toString())).toFixed(2) || 0}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{
                                    borderBottom: '1px solid #bbb',
                                    width: 'calc(100% + 40px)',
                                    flex: 'calc(100% + 40px)',
                                    maxWidth: 'calc(100% + 40px)',
                                    minWidth: 'calc(100% + 40px)',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    margin: '0px -20px 2px -12px',
                                }}></Grid>
                                <table className="customTable">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Price without tax</th>
                                            <th className="text-left">tax applied</th>
                                            <th className="text-left">round off</th>
                                            <th className="text-left">net total</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {parseFloat(saleSummary.subTotalWoTax.toString()).toFixed(2) || 0}
                                            </td>
                                            <td>
                                                {parseFloat(saleSummary.totalTax.toString()).toFixed(2) || 0}
                                            </td>
                                            <td>
                                                {(
                                                    parseFloat(Math.round(parseFloat(saleSummary.netTotal.toString())).toFixed(2)) -
                                                    parseFloat(parseFloat(saleSummary.netTotal.toString()).toFixed(2))
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                {Math.round(parseFloat(saleSummary.netTotal.toString())).toFixed(2) || 0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <Typography variant="body2" sx={{ color: colorTheme.palette.green500 }}>You saved Rs {Math.round(parseFloat(saleSummary.MRPDiscValue.toString())).toFixed(2) || 0}</Typography>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </Grid>
                        </Box>
                    </Grid>
                    <ThemeProvider theme={paymentModes}>
                        <Grid className="paymentButtons" container spacing={2} textAlign='center' flexWrap='wrap' sx={{ paddingLeft: '10px' }}>
                            <Grid item>
                                <Item onClick={() => handleOpen('Cash')} sx={{ ...transactionBtn }} className={isDisabled ? 'disabled cash' : 'cash'}>
                                    <div className="bits"><img src={cash} alt="Cash payment" /></div>
                                    Cash
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item onClick={() => handleOpen('Card')} sx={{ ...transactionBtn }} className={isDisabled ? 'disabled creditCard' : 'creditCard'}>
                                    <div className="bits"><img src={creditCard} alt="Credit card payment" /></div>
                                    Card
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item onClick={() => handleOpen('UPI')} sx={{ ...transactionBtn }} className={isDisabled ? 'disabled upi' : 'upi'}>
                                    <div className="bits"><img src={upi} alt="UPI payment" /></div>
                                    UPI
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item onClick={() => handleOpen('Multi Modes')} sx={{ ...transactionBtn }} className={isDisabled ? 'disabled multiModes' : 'multiModes'}>
                                    <div className="bits"><img src={multiModes} alt="Multi modes" /></div>
                                    Multi Modes
                                </Item>
                            </Grid>
                            {/* <Grid item>
                                <Item sx={{ ...transactionBtn }} className={isDisabled ? 'disabled holdOrder' : 'holdOrder'}>
                                    <div className="bits"><img src={holdOrder} alt="Hold order" /></div>
                                    Hold Order
                                </Item>
                            </Grid> */}
                        </Grid>
                    </ThemeProvider>
                    <PaymentModal
                        open={open}
                        handleClose={handleClose}
                        modalTitle={modalTitle}
                        saleSummary={saleSummary}
                        paymentAmtBtn={paymentAmtBtn}
                        paymentAmt={paymentAmt}
                        handleSetPayment={handleSetPayment}
                        paymnetHandler={paymnetHandler}
                        transactionCode={transactionCode}
                        transactionCodeHandler={transactionCodeHandler}
                        createInvoiceHandler={createInvoiceHandler}
                        halfPaymentHandle={halfPaymentHandle}
                        exchangeProducts={false}
                    />
                </Grid >
            </Grid >
            <Grid item xs={6} md={8} alignSelf='stretch'>
                <Box sx={{
                    ...cardItem,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    <Grid container justifyContent="space-between" alignItems='center' sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <DynamicSearchBox>
                                <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                    <InputLabel htmlFor="outlined-adornment-password">Search product/barcode</InputLabel>
                                    <OutlinedInput
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        type='text'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Search product/barcode"
                                    />
                                </FormControl>
                            </DynamicSearchBox>
                            {searchQuery && (
                                <div ref={searchProduct} className="search-result bg-white flex relative">
                                    {products && products.length > 0 ? (
                                        <ul className="overflow-auto flex-1 absolute top-0 end-0 start-0 h-96 bg-white shadow-2xl z-10">
                                            {products.map((product: any, index: number) => (
                                                <li className={`border-b border-slate-200 
                                                p-2 flex items-center gap-2 text-black 
                                                justify-between hover:bg-primary
                                                hover:text-white cursor-pointer`}
                                                    key={index}
                                                    onClick={() => handleProductClick(product)}>
                                                    <span className="flex-1">{product.prod_title}</span>
                                                    <sub><strong className="text-uppercase">{product.barCode}</strong></sub>
                                                    <strong>{product.size} {product.color}</strong>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Typography variant="body2" className="p-2">No products found.</Typography>
                                    )}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs="auto">
                            <PrintButtons
                                isDisabled={isDisabled}
                                billingSaleSummary={billing.billingDetail.saleSummary}
                                billingSelectedProduct={billing.billingDetail.selectedProducts}
                                tempBillNo={tempBillNo}
                                customerMobile={billing.billingDetail.customerDetails.contact_no_1}
                                customerName={billing.billingDetail.customerDetails.contact_name_1}
                                paymentSuccessfullClose={paymentSuccessfullClose}
                                billNumber={billNumber}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{
                        flex: 1,
                        overflow: 'auto',
                        minHeight: '390px',
                        maxHeight: '390px',
                        height: '390px',
                    }}>
                        <table className="w-full customTable">
                            <thead>
                                <tr>
                                    <th className="text-left">Barcode (SKU)</th>
                                    <th className="text-left">Product</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-left">Rate</th>
                                    <th className="text-left">Discount</th>
                                    <th className="text-right">Amount</th>
                                    <th className="text-center">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billing.billingDetail.selectedProducts.map((product: any, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            <Grid container spacing={0} alignItems='center'>
                                                <Grid item xs={12}>
                                                    {product.barCode}
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                        SKU:&nbsp;{product.prod_sku}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </td>
                                        <td className="text-nowrap">
                                            <Grid container spacing={0} direction="column">
                                                <Grid item>
                                                    {product.prod_title}
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={1}>
                                                        {product.size && (
                                                            <Grid item xs='auto'>
                                                                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                    Size:&nbsp;{product.size}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {product.colour && (
                                                            <Grid item xs='auto'>
                                                                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                    Color:&nbsp;{product.colour}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </td>
                                        <td>
                                            <div className="flex items-center flex-row justify-center overflow-hidden">
                                                <button className="p-2 bg-primaryMain text-white hover:bg-primaryMainHover flex items-center justify-center h-6 w-6 rounded-s-md" onClick={() => decrement(product)}>-</button>
                                                <input type="text" placeholder="0" value={product.quantity} className="w-7 h-6 text-center" readOnly />
                                                <button className="p-2 bg-primaryMain text-white hover:bg-primaryMainHover flex items-center justify-center h-6 w-6 rounded-e-md" onClick={() => increment(product)}>+</button>
                                            </div>
                                        </td>
                                        <td>
                                            <Grid container spacing={0} alignItems='center'>
                                                <Grid item xs={12}>
                                                    <input
                                                        type="text"
                                                        value={product.unit_price || ''}
                                                        onChange={(e) => handleUnitPriceChange(e, product)}
                                                        placeholder="0"
                                                        className='discountInp' />
                                                </Grid>
                                                {product.mrp && (
                                                    <Grid item xs='auto'>
                                                        <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                            MRP:&nbsp;{product.mrp}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </td>
                                        {product.discount_flag ? (
                                            <td>
                                                <Grid container spacing={1} justifyContent='space-between' alignItems='center' flexWrap='nowrap'>
                                                    <Grid item>
                                                        <input type="text"
                                                            value={productDiscounts[product.barCode] ? productDiscounts[product.barCode].disc_val : 0}
                                                            name={`discount-${index}`}
                                                            onChange={(e) => productDiscountHandler(e, product)}
                                                            placeholder="0"
                                                            disabled={product.unit_price !== billing.billingDetail.originalUnitPrice[product.barCode].unti_price}
                                                            className='discountInp short' />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl fullWidth variant='standard'>
                                                            <Select
                                                                labelId="discount-type"
                                                                id={`demo-simple-select-${index}`}
                                                                value={productDiscounts[product.barCode] ? productDiscounts[product.barCode].disc_type : 'amount'}
                                                                label="Type"
                                                                onChange={(e) => productDisTypeChange(e, product)}
                                                                disabled={product.unit_price !== billing.billingDetail.originalUnitPrice[product.barCode].unti_price}
                                                            >
                                                                <MenuItem value='amount' selected>flat</MenuItem>
                                                                <MenuItem value='percent'>%</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </td>
                                        ) : (
                                            <td align="center">
                                                <Typography variant="caption">Discount not applicable on this product</Typography>
                                            </td>
                                        )
                                        }
                                        <td className="text-right">
                                            {Math.round(parseFloat(product.net_total.toString())).toFixed(2)}
                                            {/* {billing.billingDetail.productDiscounts[product.barCode]
                                                ? Math.round(parseFloat(product.net_total.toString())).toFixed(2)
                                                : Math.round(parseFloat(product.sub_total_disc.toString())).toFixed(2)} */}
                                        </td>
                                        <td className="text-center">
                                            <Tooltip title="Remove item">
                                                <Button onClick={() => handleProductRemove(product.barCode)} variant="text" sx={{ padding: 0, border: 0, minWidth: '24px', color: colorTheme.palette.topLink, '&:hover': { border: 0, color: colorTheme.palette.secondary.main }, fontWeight: '600' }}>
                                                    <DeleteOutline />
                                                </Button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>

                    <Box sx={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #ddd',
                        padding: '10px 0 0',
                        flexShrink: 0,
                        color: '#f4511e'
                    }}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <Typography variant="subtitle2">
                                    Total Items: {saleSummary.total_items} pcs
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2">
                                    MRP Total: ₹ {(saleSummary.MRP).toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2">
                                    Grand Total: ₹ {Math.round(saleSummary.netTotal).toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <FeedbackModal
                open={openModal}
                handleClose={handleCloseModal}
                billingSaleSummary={billing.billingDetail.saleSummary}
                billingSelectedProduct={billing.billingDetail.selectedProducts}
                feedbackModalTitle={feedbackModalTitle}
                billNumber={billNumber}
                paymentMethod={modalTitle === 'Card' ? 'Credit Card' : modalTitle}
                paymentAmt={paymentAmt}
                payments={payments}
                customerMobile={billing.billingDetail.customerDetails.contact_no_1}
                customerName={billing.billingDetail.customerDetails.contact_name_1}
                tempBillNo={tempBillNo}
                paymentSuccessfullClose={paymentSuccessfullClose}
            />
        </>
    )
}

export default ProductsTable;