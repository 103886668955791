import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiQuery } from "../Interceptor/interceptor";
import { IProduct, IProductTax, TProductsList } from "../../Types/types";


export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery: baseApiQuery,
    tagTypes: ['products'],
    endpoints: (builder) => ({
        getProducts: builder.query<TProductsList, { search?: any }>({
            query: (params) => ({
                url: "products/list/",
                method: 'GET',
                params,
            }),
            providesTags: ['products']
        }),
        getProductTax: builder.mutation<IProductTax, IProduct>({
            query: (body) => ({
                url: 'getproducttax/',
                method: 'POST',
                body,
            })
        }),
        getMasterTax: builder.query<any, any>({
            query: () => ({
                url: 'taxes/fetch/',
                method: 'GET',
            })
        })
    })
})

export const { useGetProductsQuery, useGetProductTaxMutation, useLazyGetMasterTaxQuery } = productsApi