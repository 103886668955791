// import Grid from '@mui/material/Grid';
// import ProductsTable from "../../Components/Billing/ProductsTable";
import BillingsTabs from '../../Components/Billing/BillingsTabs';

function Billing() {
    return (
        <>
            <section id="main" className="flex flex-col overflow-auto flex-1 gap-3" style={{ padding: '5px' }}>
                <div>
                    <BillingsTabs />
                </div>
                {/* <Grid container spacing={2} alignItems='start' justifyContent='start' alignContent='baseline' flexDirection='row-reverse'>
                    <ProductsTable />
                </Grid> */}
            </section>
        </>
    );
}

export default Billing;
