import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiQuery } from "../Interceptor/interceptor";
import { TCustomer, TCustomerList, TCustomerDetails } from "../../Types/types";


export const customerApi = createApi({
    reducerPath: "customerApi",
    baseQuery: baseApiQuery,
    tagTypes: ['customer'],
    endpoints: (builder) => ({
        getCustomer: builder.query<TCustomerList, { search: any }>({
            query: ({ search }) => ({
                url: `getcustomer/?search=${search}`,
                method: "GET",
            }),
            providesTags: ['customer']
        }),
        createCustomer: builder.mutation<any, TCustomer>({
            query: (customer) => ({
                url: "createcustomer/",
                method: "POST",
                body: customer,
            })
        }),
        getCustomerDetails: builder.query<TCustomerDetails, { search: any, limit: number, offset: number }>({
            query: ({ search, limit, offset }) => ({
                url: `getcustomerdetails/?search=${search}&limit=${limit}&offset=${offset}`,
                method: "GET",
            })
        }),
        addCustomerNote: builder.mutation<any, any>({
            query: (customer) => ({
                url: "customer/notes/add/",
                method: "POST",
                body: customer,
            })
        })
    })
});

export const { useGetCustomerQuery, useCreateCustomerMutation, useGetCustomerDetailsQuery, useAddCustomerNoteMutation } = customerApi