import { createTheme } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';
import { blue, green, deepOrange, brown, indigo, teal, blueGrey, amber, red, grey } from '@mui/material/colors';
// import { Padding } from '@mui/icons-material';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        secondary: true;
    }
}
declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        blue: true;
        green: true;
    }
}

export const fontArial = createTheme({
    typography: {
        fontFamily: 'Arial Rounded MT, sans-serif',
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Arial Rounded MT, sans-serif',
                }
            }
        }
    }
});


export const colorTheme = {
    palette: {
        primary: {
            light: '#757ce8',
            main: '#9858b9',
            mainHover: '#53266a',
            dark: '#666',
            contrastText: '#fff',
            tableHead: '#6083FF66'
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
        topLink: '#bbb',
        blue500: blue[600],
        green600: green[600],
        green500: green[500],
        green50: green[50],
        deepOrange500: deepOrange[600],
        brown500: brown[600],
        indigo500: indigo[500],
        indigo400: indigo[400],
        teal500: teal[500],
        teal600: teal[600],
        blueGrey: blueGrey[500],
        amber500: amber[500],
        amber50: amber[50],
        red500: red[500],
        red50: red[50],
        grey500: grey[500],
        grey50: grey[50],
        logoPink: '#D13E80',
        logoPinkHover: '#D13f80',
    },
}

export const btnTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: colorTheme.palette.primary.main,
                    padding: '5px 10px',
                    textTransform: 'capitalize',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: colorTheme.palette.primary.mainHover,
                    }
                }
            },
            variants: [
                {
                    props: { variant: 'secondary' },
                    style: {
                        backgroundColor: colorTheme.palette.primary.dark,
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#000'
                        }
                    }
                }
            ]
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    height: '18px',
                    width: '18px',
                    color: '#fff'
                }
            }
        }
    },
});

export const modalBoxStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: '500px',
    bgcolor: '#fff',
    padding: '8px 16px',
    boxShadow: '4px 0px 4px 0px #00000040',
    borderRadius: '5px'
}

export const modalFeedbackStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 575,
    maxWidth: '5750px',
    bgcolor: '#fff',
    boxShadow: '4px 0px 4px 0px #00000040',
    borderRadius: '40px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0',
    maxHeight: '90vh',
    overflowY: 'hidden',
    outline: 0
}

export const modalFeedbackSuccess = {
    height: '40px',
    minHeight: '40px',
    width: '40px',
    minWidth: '40px',
    display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100%', backgroundColor: '#23A26D',
    outlineStyle: 'solid',
    outlineWidth: '20px',
    outlineColor: '#23A26D1F',
    margin: '40px auto'
}

export const modalFeedbackFailed = {
    height: '40px',
    minHeight: '40px',
    width: '40px',
    minWidth: '40px',
    display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100%', backgroundColor: '#FF0000',
    outlineStyle: 'solid',
    outlineWidth: '20px',
    outlineColor: '#FF00001F',
    margin: '40px auto'
}

export const modalFeedbackTable = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '10px 0px',
                    border: '0',
                }
            }
        }
    }
});

export const cardItem = {
    boxShadow: '0px 2px 8px -4px rgba(0, 0, 0, 0.2)',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    padding: '15px',
    gap: '15px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    overflow: "hidden",
    height: "100%",
    backgroundColor: '#fff'
}

export const labelItem = {
    color: colorTheme.palette.primary.dark,
    fontSize: '13px',
    fontWeight: '600'
}

export const transactionBtn = {
    border: '0.25px solid #00000033',
    fontWeight: 'bold',
    cursor: "pointer",
    minHeight: '55px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    padding: '7px 10px'
}

export const transactionModeBtn = createTheme({
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    MuiPaper: {
                        backgroundColor: 'red'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'green',
                    border: '0.25px solid #00000033', color: '#fff', fontWeight: 'bold', cursor: "pointer"
                }
            },
            variants: [
                {
                    props: { variant: 'blue' },
                    style: {
                        backgroundColor: '#17B1E1',
                    }
                }
            ]
        }
    }
});

export const navLinks = createTheme({
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    padding: '0.75rem !important'
                }
            }
        }
    }
});

export const expandedSidebar = createTheme({
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.outerGrid': {
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        bottom: '0',
                        height: '100vh',
                        width: 'auto',
                        backgroundColor: colorTheme.palette.primary.main,
                        zIndex: '999',
                        right: 'auto',
                        transition: 'all 0.3s ease 0s',
                        margin: '0',
                        display: 'block'
                    }
                }
            }
        }
    }
})

export const plainAccordion = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '24px',
                    margin: '0',
                    '&:hover': {
                        color: colorTheme.palette.indigo500
                    },
                    '&.Mui-expanded': {
                        color: colorTheme.palette.indigo500,
                        margin: '0'
                    },
                    '&:before': {
                        display: 'none'
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: '0',
                    minHeight: 'unset',
                    '&.Mui-expanded': {
                        minHeight: 'unset'
                    },
                    '&:hover': {
                        '.MuiAccordionSummary-content': {
                            color: colorTheme.palette.indigo500,
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            color: colorTheme.palette.indigo500,
                        }
                    }
                },
                content: {
                    margin: '0',
                    color: colorTheme.palette.topLink,
                    '&.Mui-expanded': {
                        margin: '0',
                        color: colorTheme.palette.indigo500,
                        '.MuiTypography-root,.MuiLink-root': {
                            color: colorTheme.palette.indigo500,
                        }
                    }
                },
                expandIconWrapper: {
                    color: colorTheme.palette.topLink,
                    '&.Mui-expanded': {
                        color: colorTheme.palette.indigo500,
                    }
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '16px 0 0 30px',
                    margin: '10px 0 0',
                    borderLeft: '1px solid',
                    borderColor: colorTheme.palette.topLink,
                    '.MuiTypography-root,.MuiLink-root': {
                        fontSize: '14px',
                        textDecoration: 'none',
                        color: colorTheme.palette.topLink,
                        fontWeight: '600',
                        '&.active': {
                            color: colorTheme.palette.indigo500
                        },
                        '&:hover': {
                            color: colorTheme.palette.indigo500
                        }
                    }
                }
            }
        }
    }
});

export const selectDopdown = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    '& .MuiFormLabel-root': {
                        transform: 'translate(14px,15px)',
                        '&.MuiInputLabel-animated.MuiInputLabel-shrink': {
                            top: '0',
                            transform: 'translate(14px, -9px) scale(0.75)',
                            color: 'rgba(0, 0, 0, 0.6)'
                        },
                        '&.MuiInputLabel-root': {
                            color: '#66666640',
                        }
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    paddingRight: '10px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
                input: {
                    padding: '16px 13px',
                },
                notchedOutline: {
                    border: '1px solid rgba(102, 102, 102, 0.40)',
                    borderRadius: '8px',
                    legend: {
                        color: 'red'
                    }
                }
            }
        }
    },
});

export const cardLayout = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '12px'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#98AEFF80',
                    '& .MuiCardHeader-title': {
                        fontSize: '20px',
                        fontWeight: '600'
                    }
                }
            }
        }
    }
});

export const topMenu = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    marginBottom: '-3px',
                    letterSpacing: '0.5px',
                    fontWeight: '600',
                    '&.MuiLink-root': {
                        textDecoration: 'none',
                        '.MuiTypography-root': {
                            padding: '0',
                            border: '0',
                            marginBottom: 'unset',
                        },
                        '.MuiSvgIcon-root': {
                            color: colorTheme.palette.topLink,
                            height: '20px',
                            width: '20px',
                        }
                    },
                    '&.billing': {
                        '&.active , &:hover': {
                            borderColor: colorTheme.palette.primary.main,
                            '.MuiTypography-root': {
                                color: colorTheme.palette.primary.main,
                            },
                            '.MuiSvgIcon-root': {
                                color: colorTheme.palette.primary.main
                            }
                        }
                    },
                    '&.invoices': {
                        '&.active , &:hover': {
                            borderColor: colorTheme.palette.indigo500,
                            '.MuiTypography-root': {
                                color: colorTheme.palette.indigo500
                            },
                            '.MuiSvgIcon-root': {
                                color: colorTheme.palette.indigo500
                            }
                        }
                    },
                    '&.customers': {
                        '&.active , &:hover': {
                            borderColor: colorTheme.palette.logoPink,
                            '.MuiTypography-root': {
                                color: colorTheme.palette.logoPink
                            },
                            '.MuiSvgIcon-root': {
                                color: colorTheme.palette.logoPink
                            }
                        }
                    },
                    '&.settings': {
                        '&.active , &:hover': {
                            borderColor: colorTheme.palette.blueGrey,
                            '.MuiTypography-root': {
                                color: colorTheme.palette.blueGrey
                            },
                            '.MuiSvgIcon-root': {
                                color: colorTheme.palette.blueGrey
                            }
                        }
                    },
                }
            }
        }
    }
});

export const searchBox = createTheme({
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: colorTheme.palette.topLink,
                    fontSize: '14px',
                    lineHeight: '22px',
                    '&.Mui-focused': {
                        color: colorTheme.palette.primary.main
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderColor': colorTheme.palette.primary.dark,
                    '--TextField-brandBorderHoverColor': colorTheme.palette.primary.light,
                    '--TextField-brandBorderFocusedColor': colorTheme.palette.primary.main,
                    '& .MuiSvgIcon-root': {
                        color: colorTheme.palette.topLink,
                    },
                    '& label.Mui-focused': {
                        color: colorTheme.palette.primary.main,
                    },
                    '&.MuiOutlinedInput-root': {
                        borderColor: colorTheme.palette.primary.dark,
                        '& input': {
                            fontSize: '12px'
                        }
                    },
                    '&.Mui-focused': {
                        borderColor: colorTheme.palette.primary.main,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colorTheme.palette.primary.main,
                            '& span': {
                                color: colorTheme.palette.primary.main,
                            }
                        },
                        '& .MuiSvgIcon-root': {
                            color: colorTheme.palette.primary.main,
                        }
                    }
                },
            },
        }
    }
});

export const paymentModes = createTheme({
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    // position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 99,
                    padding: '5px',
                    margin: 0,
                    width: '100%',
                    background: '#f4f4f4',
                    border: 0,
                    // backgroundImage: 'linear-gradient(45deg, #000, #333 95%)',
                    '& .MuiGrid-root': {
                        position: 'relative',
                        flex: '50%',
                        padding: '5px !important',
                        '& .MuiPaper-root': {
                            transition: 'all 0.5s ease-in-out 0s',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            position: 'relative',
                            overflow: 'hidden',
                            boxShadow: '0px 2px 8px -4px rgba(0, 0, 0, 0.2)',
                            borderColor: '#f4f4f4',
                            textTransform: 'uppercase',
                            // color: '#fff',
                            '& .bits': {
                                position: 'relative',
                                transition: 'all 0.3s ease 0s',
                            },
                            '& .bits:before': {
                                content: '""',
                                display: 'block',
                                height: '25px',
                                width: '25px',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: '-5px',
                                left: '-5px',
                                opacity: '0.8',
                                zIndex: 1,
                                transition: 'all 0.3s ease 0s',
                                transformOrigin: 'center'
                            },
                            '&.creditCard': {
                                // color: '#9492CC',
                                // backgroundImage: 'linear-gradient(45deg, #000, #333 95%)',
                                '& .bits:before': {
                                    backgroundImage: 'linear-gradient(45deg, #B6B5DC, #9492CC)',
                                    borderColor: '#9492CC',
                                    '&:hover': {
                                        backgroundImage: 'linear-gradient(45deg, #9492CC,#B6B5DC)',
                                    }
                                }
                            },
                            '&.multiModes': {
                                // color: '#CACC92',
                                // backgroundImage: 'linear-gradient(45deg, #000, #333 95%)',
                                '& .bits:before': {
                                    backgroundImage: 'linear-gradient(45deg, #DBDCB5,#CACC92)',
                                    borderColor: '#DBDCB5',
                                    '&:hover': {
                                        backgroundImage: 'linear-gradient(45deg, #CACC92,#DBDCB5)',
                                    }
                                }
                            },
                            '&.upi': {
                                // color: '#CC92AE',
                                // backgroundImage: 'linear-gradient(45deg, #000, #333 95%)',
                                '& .bits:before': {
                                    backgroundImage: 'linear-gradient(45deg, #DCB5C8,#CC92AE)',
                                    borderColor: '#DCB5C8',
                                    '&:hover': {
                                        backgroundImage: 'linear-gradient(45deg, #CC92AE,#DCB5C8)',
                                    }
                                }
                            },
                            '&.cash': {
                                // color: '#92CCB0',
                                // backgroundImage: 'linear-gradient(45deg, #000, #333 95%)',
                                '& .bits:before': {
                                    backgroundImage: 'linear-gradient(45deg, #B5DCC9,#92CCB0)',
                                    borderColor: '#B5DCC9',
                                    '&:hover': {
                                        backgroundImage: 'linear-gradient(45deg,#92CCB0,#B5DCC9)',
                                    }
                                }
                            },
                            '&.holdOrder': {
                                // color: '#CCCCCA',
                                // backgroundImage: 'linear-gradient(45deg, #000, #333 95%)',
                                '& .bits:before': {
                                    backgroundImage: 'linear-gradient(45deg, #E5E5E4,#CCCCCA)',
                                    borderColor: '#E5E5E4',
                                    '&:hover': {
                                        backgroundImage: 'linear-gradient(45deg,#CCCCCA,#E5E5E4)',
                                    }
                                }
                            },
                            '&:hover': {
                                'img': {
                                    transform: 'scaleX(-1)'
                                },
                                '& .bits:before': {
                                    transform: 'scale(1.1)'
                                }
                            },
                            'img': {
                                height: '35px',
                                width: '35px',
                                transition: 'all 0.3s ease 0s',
                                zIndex: 2,
                                position: 'relative',
                                // filter: 'brightness(0) invert(1)'
                            }
                        }
                    }
                },
            }
        }
    }
});

export const tabs = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: '40px',
                    '& .MuiTabs-indicator': {
                        backgroundColor: colorTheme.palette.primary.main
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderColor: '#f7f7f7 #f7f7f7 #efefef #f7f7f7',
                    borderWidth: '1px 3px 2px 1px',
                    borderStyle: 'solid',
                    background: '#efefef',
                    borderRadius: '10px 10px 0 0',
                    '&:hover': {
                        backgroundColor: '#fff',
                        borderColor: '#fff'
                    },
                    '&.MuiTab-root': {
                        padding: '5px 10px',
                        textTransform: 'capitalize',
                        fontWeight: '600',
                        fontSize: '12px',
                        letterSpacing: '0.75px',
                        minHeight: '40px',
                        '& .MuiBox-root span': {
                            height: '20px',
                            width: '20px',

                        },
                        '&.Mui-selected': {
                            backgroundColor: '#fff',
                            color: colorTheme.palette.primary.main,
                        }
                    },
                    '&.MuiButton-root': {
                        minWidth: '45px',
                        backgroundColor: colorTheme.palette.primary.dark,
                        color: '#fff',
                        borderRadius: '10px 10px 0 0',
                        padding: '0',
                        '&:hover': {
                            backgroundColor: '#000',
                            color: '#fff',
                        }
                    },
                }
            }
        }
    }
})

export const SaveSvg = () => {
    return (
        <SvgIcon>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 18H16C17.1 18 18 17.1 18 16V5C18 4.73 17.9 4.48 17.71 4.29L13.71 0.29C13.52 0.1 13.27 0 13 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18ZM12 16H6V11H12V16ZM10 4H8V2H10V4ZM2 2H4V6H12V2H12.59L16 5.41V16H14V11C14 9.9 13.1 9 12 9H6C4.9 9 4 9.9 4 11V16H2V2Z" fill="white" />
            </svg>
        </SvgIcon>
    )
}

export const PrintSvg = () => {
    return (
        <SvgIcon>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 5H16V0H4V5H3C1.35 5 0 6.35 0 8V15C0 16.1 0.9 17 2 17H4V20H16V17H18C19.1 17 20 16.1 20 15V8C20 6.35 18.65 5 17 5ZM6 2H14V5H6V2ZM14 18H6V14H14V18ZM18 15H16V12H4V15H2V8C2 7.45 2.45 7 3 7H17C17.55 7 18 7.45 18 8V15Z" fill="white" />
            </svg>
        </SvgIcon>
    )
}

export const EditSvg = () => {
    return (
        <SvgIcon sx={{
            height: '20px',
            width: '20px',
            '&:hover': {
                color: colorTheme.palette.primary.main
            }
        }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_60_3175)">
                    <path d="M16.1286 6.12155L8.4808 13.7684C8.17189 14.0773 7.77871 14.2876 7.35035 14.3732L4.09668 15.0238L4.74735 11.7706C4.83295 11.3427 5.04332 10.9492 5.35223 10.6407L13.0003 2.99379M16.1286 6.12155L17.6927 4.55767C18.1247 4.12573 18.1247 3.42573 17.6927 2.99379L16.1286 1.42991C15.6965 0.997972 14.9964 0.997972 14.5644 1.42991L13.0003 2.99379M16.1286 6.12155L13.0003 2.99379" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.17041 18.894H17.5586" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_60_3175">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export const DropdownArrow = () => {
    return (
        <SvgIcon sx={{
            height: '24px',
            width: '24px',
        }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_107_2176)">
                    <path d="M6 9L12 15L18 9" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_107_2176">
                        <rect width="24" height="24" fill="white" transform="matrix(0 -1 1 0 0 24)" />
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    )
}